import React from 'react';
import { useSelector } from "react-redux";
import { getAppState } from "../../../../redux-modules/app/selector";
import IFrame from "../iframe/IFrame";
import { getTappById } from "../../../../utils/tapp/custom-tapp";
import TAPPIDS from "../../../../constants/tapp-ids";
import {getEnvState} from "../../../../redux-modules/env/selector";

const LoginOverlay = () => {
    const {
        loginOverlay,
    } = useSelector(getAppState);


    const {
        isDesktop
    } = useSelector(getEnvState);

    if(!loginOverlay) return null;

    const tapp = getTappById(TAPPIDS.LOGIN_TAPP_ID);

    return (
        <div style={{ position: 'fixed', zIndex: 400, top:0,left:0,right:0,bottom:0 }}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top:0,left:0,right:0,bottom:loginOverlay.tokenType === 'default' ? isDesktop ? -8: 50 : isDesktop ? -8 : 0 }}><IFrame
                tappId={tapp?.id}
                isActive
                tappUrl={((tapp?.source ? (tapp?.source + `&tokenType=${(loginOverlay.tokenType === 'default' ?  '': '12')}&${new URL(location.href).searchParams.toString()}`) : '') || `https://login.chayns.net/v3/index.html?AppVersion=2&SiteID=60021-08989&color=005eb8&qrLogin=1&staticMode=1${isDesktop ? '&desktop=1' : ''}&colormode=1&showLogin=1&tokenType=${(loginOverlay.tokenType === 'default' ?  '': '12')}&sso_exclude=6`).replace('&noBackground=0', '') + "&showLogin=1&hideLogo=1&" + new URL(location.href).searchParams.toString()}
                style={{
                    width: '100vw',
                    minHeight: '100vh'
                }}
            /></div>
        </div>
    )
}

export default LoginOverlay;

import React, {useCallback, memo, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { INTERCOM } from '../../../../../../constants/types';

import { getAppState } from '../../../../../../redux-modules/app/selector';
import { setSelectedItem } from '../../../../../../redux-modules/app/actions';
import appCall from '../../../../../../utils/appCall';
import ApiV5Tapp from '../../../ApiV5Tapp/ApiV5Tapp';
import { IntercomListItem } from '../../../../../../constants/defaults';
import TAPPIDS from '../../../../../../constants/tapp-ids';
import {getIntercomThreadByIdState} from "../../../../../../redux-modules/intercom/selector";

const IntercomItem = ({
    id,
}) => {
    const dispatch = useDispatch();
    const app = useSelector(getAppState);
    const {
        selectedItem,
    } = app;

    const thread = useSelector(getIntercomThreadByIdState(id));

    const isSelected = id === selectedItem?.id && selectedItem?.type === INTERCOM;

    const handleSelect = useCallback(() => {
        if (isSelected) return;
        appCall(292, {
            show: false,
        }, {
            awaitResult: false,
        });
        dispatch(setSelectedItem({
            ...thread,
            type: INTERCOM,
        }));
    }, [isSelected, dispatch, thread]);

    return (
        <ApiV5Tapp
            type="client-module"
            system={IntercomListItem}
            customData={{
                onClick: handleSelect,
                thread,
                isSelected,
            }}
            tappId={TAPPIDS.INTERCOM}
        />
    );
};

IntercomItem.propTypes = {
    id: PropTypes.string.isRequired,
};

IntercomItem.displayName = 'IntercomItem';

export default memo(IntercomItem);

import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import LeftBar from './LeftBar';
import { getActiveDavidSiteId, getActiveSmartClientTapp, getAppState } from '../../redux-modules/app/selector';
import { getTappsState } from '../../redux-modules/tapps/selector';
import TAPPIDS from '../../constants/tapp-ids';
import { setActiveSmartClientTapp, setActiveTapp } from '../../redux-modules/app/actions';

const LeftBarWrapper = ({ handleLoadTapp }) => {
    const app = useSelector(getAppState);
    const {
        colorMode,
        activeTapp,
        expandedMenu
    } = app;

    const tapps = useSelector(getTappsState);
    const activeSmartClientTapp = useSelector(getActiveSmartClientTapp);
    const activeDavidSiteId = useSelector(getActiveDavidSiteId);

    const dispatch = useDispatch();

    const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);

    const icons = [];

    tapps.filter(x => !x.isHidden).forEach((tapp) => {
        if(tapp.id === TAPPIDS.SMART_CLIENT || tapp.id === TAPPIDS.CALENDAR) {

        } else {
            if(tapp.tappId === TAPPIDS.SITES) {
                icons.push({
                    icon: 'chayns-icon-animated',
                    tappId: TAPPIDS.SITES,
                    badge: '',
                    name: 'InfoCenter',
                })
            } else {
                icons.push({
                    icon: tapp.icon,
                    tappId: tapp.tappId,
                    badge: tapp.badge,
                    name: tapp.name,
                })
            }
        }
    })

    useEffect(() => {
        if (activeTapp === TAPPIDS.SMART_CLIENT && typeof window.handleSetSelection === 'function') {
            window.handleSetSelection({
                folderId: activeSmartClientTapp,
                davidSiteId: activeDavidSiteId,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSmartClientTapp, activeTapp, activeDavidSiteId]);

    return (
        <div style={{marginTop: 10}}>
            <LeftBar
                handleClick={handleLoadTapp}
                darkMode={isDarkMode}
                icons={icons}
                activeTapp={activeTapp}
                handleSmartClientButtonClick={(tappId) => {
                    dispatch(setActiveTapp(TAPPIDS.SMART_CLIENT));
                    dispatch(setActiveSmartClientTapp(tappId));
                }}
                activeSmartClientTapp={activeSmartClientTapp}
                expandedMenu={expandedMenu}
            />
        </div>
    );
};

LeftBarWrapper.propTypes = {
    handleLoadTapp: PropTypes.func.isRequired,
};

export default LeftBarWrapper;

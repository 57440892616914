import React from 'react';
import ErrorBoundary from '../components/error-boundary/ErrorBoundary';

const withErrorBoundary = (Component, section = null) => (props) => {
    // eslint-disable-next-line react/prop-types
    const { tappId } = props;
    return (
        <ErrorBoundary section={section || tappId || 'withErrorBoundary'}>
            {React.createElement(Component, props)}
        </ErrorBoundary>
    );
};

export default withErrorBoundary;

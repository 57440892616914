/* eslint-disable jsx-a11y/alt-text */
import React, { memo, useRef, useState, useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {SITE, SITE2} from '../../../../../constants/types';
import { setContextMenu } from '../../../../../redux-modules/context-menu/actions';
import { getTextStringState } from '../../../../../redux-modules/text-strings/selector';
import PageIcon from './page-icon/PageIcon';
import { getUserState } from '../../../../../redux-modules/user/selector';
import logger from "../../../../../constants/logger";
import {getContextMenuState} from "../../../../../redux-modules/context-menu/selector";
import clsx from "clsx";

type PageIconProps = {
  base64?: string,
  url?: string,
  name: string,
};

type SiteTypes = {
    siteId: string,
    name: string,
    color: string,
    isKnown: boolean,
    isDraggingFirefox?: boolean,
    tappId?: number,
    openDialog?: boolean,
    domain: string,
    isSearchResult?: boolean,
    isClient: boolean,
    isMyChaynsApp: boolean,
    searchString: string,
    isPage?: boolean,
    pageId?: number,
    pageIcon?: PageIconProps,
    pageName?: string,
};

const Site: React.FC<SiteTypes> = ({
    siteId,
    name,
    color,
    isKnown,
    isDraggingFirefox,
    tappId,
    openDialog,
    domain,
    isSearchResult,
    isClient,
    isMyChaynsApp,
    searchString,
    isPage,
    pageId,
    pageIcon,
    pageName,
    index
}) => {
    const dispatch = useDispatch();
    const ref = useRef(null);

    const user = useSelector(getUserState);
    const textStrings = useSelector(getTextStringState);
    const unnamedSiteText = textStrings['txt_chayns_chaynsweb_unnamed_site'] || 'Unbenannte Site';

    const [iconAvailable, setIconAvailable] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState(null);

    const contextMenu = useSelector(getContextMenuState);
    const squareImgWrapperClassnames = classnames('infocenter__square__img-wrapper', {
        is_mobile: isMyChaynsApp,
    });

    const squareClassnames = classnames('infocenter__square', 'fadeInUp', isSearchResult ? 'useAnimation' : '');

    const siteColor = useMemo(() => (color && /^[a-fA-F0-9]{3,8}$/.test(color) ? `#${color}` : color), [color]);

    const showName = isPage && pageName ? `${pageName} - ${name ?? unnamedSiteText}` : (name ?? unnamedSiteText);

    const usedPageIcon = useMemo(() => {
        if (!isPage) {
            return null;
        }
        if (pageIcon?.base64) {
            return `data:image/png;base64,${pageIcon.base64}`;
        }
        if (pageIcon?.url) {
            return pageIcon.url;
        }
        return `fas ${pageIcon.name}`;
    }, [isPage, pageIcon]);

    useMemo(() => {
        setBackgroundColor(iconAvailable || !imageLoaded ? '' : siteColor);
    }, []);

    return (
        <>
            <div
                className={squareClassnames}
                ref={ref}
                onContextMenu={(e) => {
                    if (searchString) {
                        e.preventDefault();
                        e.stopPropagation();
                        dispatch(setContextMenu({
                            x: e.clientX,
                            y: e.clientY + window.scrollY,
                            id: siteId,
                            contextType: SITE,
                        }));
                    }
                }}
                onClick={() => {
                    if (contextMenu.contextType === SITE2) {
                        return;
                    }
                    if (isDraggingFirefox || typeof chayns === 'undefined') {
                        return;
                    }

                    logger().info({
                        message: 'Click on Site',
                        section: 'search stats',
                        customText: searchString,
                        data: {
                            id: siteId,
                            name,
                            color,
                            siteId,
                            isKnown,
                            isDraggingFirefox,
                            tappId,
                            openDialog,
                            domain,
                            isSearchResult,
                            isClient,
                            isMyChaynsApp,
                            searchString,
                        },
                    });
                    if (isMyChaynsApp) {
                        const customDomain = chayns.env.appVersion < 6405 ? encodeURIComponent(domain) : domain;
                        const tappIdUrl = tappId || pageId ? `/tapp/index/${tappId || pageId}` : undefined;
                        const chaynsCall = {
                            action: 142,
                            value: {
                                siteId,
                                color: null,
                                colorMode: null,
                                tappIdUrl,
                                title: name,
                                login: isKnown,
                                noPushCheck: !isKnown,
                                customDomain: `${customDomain}${tappIdUrl || ''}`,
                            },
                        };
                        if (chayns.env.isAndroid && chayns.env.isApp && chayns.env.appVersion >= 6464 && chayns.env.appVersion < 6513) {
                            delete chaynsCall.value.customDomain;
                        }
                        // customDomain should only set the domain and only if it differs from chayns.net/{siteId}
                        if (chayns.env.isApp && ((chayns.env.isAndroid && chayns.env.appVersion > 6860) || chayns.env.isIOS)) {
                            if (domain === `chayns.net/${siteId}`) {
                                delete chaynsCall.value.customDomain;
                            } else {
                                chaynsCall.value.customDomain = customDomain;
                            }
                        }
                        chayns.invokeCall(chaynsCall);
                    } else {
                        const url = new URL(`https://chayns.net/${siteId}/tapp/${tappId || pageId || ''}`);
                        if (openDialog) {
                            url.searchParams.append('chaynsdevisit', `${chaynsInfo.User.ID}`);
                        }
                        if (user.incognito) {
                            url.searchParams.set('incognitoMode', '1');
                        }
                        chayns.openUrlInBrowser(url.href, '_blank');
                    }
                }}
            >
                <div
                    className={squareImgWrapperClassnames}
                >
                    <img
                        className={clsx("infocenter__square__img", {active: contextMenu.id === siteId})}
                        src={`https://sub60.tobit.com/l/${siteId}?size=114`}
                        style={backgroundColor ? { backgroundColor } : null}
                        onLoad={() => {
                            setImageLoaded(true);
                            setIconAvailable(true);
                        }}
                        onError={() => {
                            setImageLoaded(true);
                            setIconAvailable(false);
                        }}
                        onContextMenu={(e) => {
                            if (!searchString) {
                                const { left, top, height, width } = ref.current.getBoundingClientRect();
                                dispatch(setContextMenu({
                                    x: left + (width/2) + 3,
                                    y: top + window.scrollY + (height/2) -60 + 5,
                                    id: siteId,
                                    contextType: SITE2,
                                    oldIndex: index
                                }));
                                e.preventDefault();
                                e.stopPropagation();
                                return false;
                            }
                            return true;
                        }}
                    />
                    {isPage && (
                        <PageIcon
                            pageIcon={pageIcon}
                            siteColor={siteColor}
                            usedPageIcon={usedPageIcon}
                        />
                    )}
                </div>
                <div
                    className={classnames('infocenter__square__subline', {
                        'small-font': isMyChaynsApp,
                    })}
                >
                    <span>{showName}</span>
                </div>
            </div>
            <style jsx>
                {`
                  .useAnimation {
                    opacity: 1;
                    animation-name: fadeIn;
                    animation-iteration-count: 1;
                    animation-timing-function: ease-in;
                    animation-duration: .2s;
                  }

                  .infocenter__square {
                    user-select: none;
                    cursor: pointer;
                    margin-bottom: 1vh;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    overflow: visible;
                    -webkit-touch-callout: none;

                    &__img-wrapper {
                      height: 60px;
                      width: 60px;
                      position: relative;
                      display: inline-block;
                      transition: all .3s;
                      background-color: rgba(var(--chayns-color-rgb--text), .1);

                      &:after {
                        content: '';
                        padding-top: 100%;
                        display: block;
                      }

                      &:hover {
                        transform: scale(1.15);
                      }

                      &.is_mobile:hover {
                        transform: none;
                      }
                    }

                    &__img {
                      position: absolute;
                      background-position: center;
                      background-size: cover;
                      top: 0;
                      right: 0;
                      left: 0;
                      bottom: 0;
                      width: 100%;
                      outline: 0.05px solid rgba(0, 0, 0, 0.1);
                      outline-offset: -1px;
                      opacity: 1;
                      height: 100%;
                      object-fit: cover;
                      object-position: center;
                      text-indent: -10000px;
                    }

                    &__subline {
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: block;
                      width: 100%;
                      text-align: center;
                      padding-top: 5px;
                      height: 45px;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      line-height: 1.4;
                      width: 90%;

                      &.small-font {
                        font-size: 12px;
                        height: 40px;
                        margin-bottom: 10px;
                      }
                    }
                  }

                  @keyframes progress {
                    0% {
                      transform: translate3d(-100%, 0, 0);
                    }
                    100% {
                      transform: translate3d(100%, 0, 0);
                    }
                  }

                  a:hover {
                    opacity: 1 !important;
                  }

                  @keyframes zoomIn {
                    from {
                      transform: rotate(0deg) scale(0);
                    }
                    to {
                      transform: rotate(360deg) scale(1);
                    }
                  }

                  @keyframes fadeIn {
                    from {
                      opacity: 0;
                    }
                    to {
                      opacity: 1;
                    }
                  }

                  @keyframes fadeInUp {
                    from {
                      opacity: 0;
                      transform: translate3d(0, 20px, 0);
                    }
                    to {
                      opacity: 1;
                      transform: translate3d(0, 0, 0);
                    }
                  }
                `}
            </style>
        </>
    );
};

Site.displayName = 'Site';

Site.propTypes = {
    name: PropTypes.string,
    color: PropTypes.string,
    siteId: PropTypes.string.isRequired,
    isKnown: PropTypes.bool.isRequired,
    isDraggingFirefox: PropTypes.bool,
    tappId: PropTypes.number,
    openDialog: PropTypes.bool,
    domain: PropTypes.string,
    isSearchResult: PropTypes.bool,
    isClient: PropTypes.bool,
    isMyChaynsApp: PropTypes.bool,
    searchString: PropTypes.string,
    pageId: PropTypes.number,
    isPage: PropTypes.bool,
    pageName: PropTypes.string,
};

Site.defaultProps = {
    name: null,
    color: null,
    isDraggingFirefox: false,
    tappId: null,
    openDialog: false,
    domain: null,
    isSearchResult: false,
    isClient: false,
    isMyChaynsApp: false,
    searchString: '',
    pageId: null,
    isPage: false,
    pageName: null,
    pageIcon: null,
};

export default memo(Site);

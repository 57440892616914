/* eslint-disable linebreak-style */
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';

import {openSmartClient} from '../../utils/smart-client/openSmartClient';
import {MAIL_REGEX} from '../../utils/regex';
import {
    ANSWER_ALL_MAIL,
    ANSWER_MAIL,
    DISTRIBUTE,
    EDIT_MAIL,
    FAX,
    FORWARD_MAIL,
    INTERCOM,
    MAIL,
    SITE,
    SITE2,
    VOICE
} from '../../constants/types';
import {reportSite} from '../../api/sites/post/reportSite';
import {getAppState} from '../../redux-modules/app/selector';
import {getEnvState} from '../../redux-modules/env/selector';
import {getTextStringState} from '../../redux-modules/text-strings/selector';
import {getContextMenuState} from '../../redux-modules/context-menu/selector';
import {setContextMenu} from '../../redux-modules/context-menu/actions';
import {setDialog} from '../../redux-modules/dialog/actions';
import {getActiveServerState, getMailByIdState} from '../../redux-modules/smart-client/selector';
import {handleDeleteMail, handleUpdateReadStatus, setResubmit} from '../../redux-modules/smart-client/actions';
import {getIntercomThreadByIdState} from '../../redux-modules/intercom/selector';
import {getSearchSiteByIdState} from '../../redux-modules/search/selector';
import {RootState} from '../../redux-modules/rootReducer';
import {handleDeleteSite, setDraggedSite, setIgnoreFocused, setSites} from "../../redux-modules/sites/actions";
import {getSitesState} from "../../redux-modules/sites/selector";
import {Location} from "../../types/location";
import cloneDeep from "lodash.clonedeep";
import appCall from "../../utils/appCall";
import ChaynsContextMenu from '@chayns-components/ContextMenu';
import {DialogButtonType} from "chayns-api";
import DialogHelper from "../../calls/dialog/DialogHelper";

const ContextMenu: React.FC = () => {
    const dispatch = useDispatch();
    const app = useSelector(getAppState);
    const {
        colorMode,
        isClient,
    } = app;
    const sites = useSelector(getSitesState);

    const locations: Array<Location> = useMemo(() => {
        const cloneSites = cloneDeep(sites?.values || []);
        return cloneSites.filter((site) => !site?.isHidden);
    }, [sites?.values]);

    const env = useSelector(getEnvState);
    const {
        isMobile,
        isTablet,
        isMyChaynsApp,
    } = env;

    const textStrings = useSelector(getTextStringState);

    const contextMenu = useSelector(getContextMenuState);
    const {
        id,
        isList,
        contextType,
        x,
        y,
        oldIndex
    } = contextMenu;

    const server = useSelector(getActiveServerState);
    const mail = useSelector((state: RootState) => getMailByIdState(state, id));
    const site = useSelector((state: RootState) => getSearchSiteByIdState(state, id));

    const contextMenuRef = useRef(null);
    const [active, setActive] = useState(true);

    const hideMenu = useCallback(() => {
        if (contextMenuRef.current) {
            contextMenuRef.current.hide();
            dispatch(setContextMenu({
                id: null,
                x: null,
                y: null,
                contextType: null,
            }));
            setActive(false);
        }
    }, [dispatch]);

    const showMenu = () => {
        if (contextMenuRef.current) {
            contextMenuRef.current.show();
            setActive(true);
        }
    };

    useEffect(() => {
        window.addEventListener('blur', hideMenu);
        return () => window.removeEventListener('blur', hideMenu);
    }, [hideMenu, isMobile, isTablet]);

    useEffect(() => {
        if (isClient) {
            if (active && !id) {
                hideMenu();
            } else {
                showMenu();
            }
        }
    }, [active, hideMenu, id, isClient]);

    const getItems = () => {
        if (typeof chayns === 'undefined') {
            return [];
        }
        switch (contextType) {
            case MAIL: {
                if (mail && server) {
                    const {
                        isRead,
                        type: mailType,
                        from,
                        properties: {
                            isEncrypted,
                            isSigned,
                        },
                    } = mail;
                    const items = [
                        {
                            type: 'read',
                            onClick: () => {
                                hideMenu();
                                dispatch(handleUpdateReadStatus({ ...mail, isRead: !isRead }));
                            },
                            icon: 'far fa-check',
                            text: isRead ? textStrings?.['txt_chayns_de_mark_unread'] || 'Als ungelesen markieren' : textStrings?.['txt_chayns_de_mark_read'] || 'Als gelesen markieren',
                            className: 'context-menu-spacer',
                        },
                        {
                            type: 'edit',
                            onClick: () => {
                                hideMenu();
                                openSmartClient(id, EDIT_MAIL, server.davidSiteId, isMyChaynsApp);
                            },
                            icon: 'far fa-edit',
                            text: textStrings?.['txt_chayns_de_edit'] || 'Bearbeiten',
                        },
                        {
                            type: 'answer',
                            onClick: () => {
                                hideMenu();
                                openSmartClient(id, ANSWER_MAIL, server.davidSiteId, isMyChaynsApp);
                            },
                            icon: 'far fa-reply',
                            text: textStrings?.['txt_chayns_de_reply'] || 'Antworten',
                        },
                        {
                            type: 'answerAll',
                            onClick: () => {
                                hideMenu();
                                openSmartClient(id, ANSWER_ALL_MAIL, server.davidSiteId, isMyChaynsApp);
                            },
                            icon: 'far fa-reply-all',
                            text: textStrings?.['txt_chayns_de_reply_all'] || 'Allen antworten',
                        },
                        {
                            type: 'forward',
                            onClick: () => {
                                hideMenu();
                                openSmartClient(id, FORWARD_MAIL, server.davidSiteId, isMyChaynsApp);
                            },
                            icon: 'far fa-share',
                            text: textStrings?.['txt_chayns_de_forward'] || 'Weiterleiten',

                        },
                        {
                            type: 'resubmit',
                            onClick: () => {
                                hideMenu();
                                dispatch(setResubmit(id));
                            },
                            icon: 'far fa-bell',
                            text: textStrings?.['txt_chayns_de_resubmit'] || 'Wiedervorlegen',
                            className: isList ? 'context-menu-spacer' : '',
                        },
                        {
                            type: 'distribute',
                            onClick: () => {
                                dispatch(setDialog({
                                    data: mail,
                                    dialogType: DISTRIBUTE,
                                }));
                                if (!isMobile && !isTablet) {
                                    hideMenu();
                                }
                            },
                            icon: 'far fa-arrow-right',
                            text: textStrings?.['txt_chayns_de_distribute'] || 'Verteilen',
                        },
                        {
                            type: 'delete',
                            onClick: () => {
                                hideMenu();
                                dispatch(handleDeleteMail({
                                    id: mail.id,
                                    subject: mail.subject,
                                    skipDialog: false,
                                }));
                            },
                            icon: 'far fa-trash',
                            text: textStrings?.['txt_chayns_de_delete'] || 'Löschen',
                        },

                    ];
                    if ([INTERCOM, VOICE, FAX].indexOf((mailType || '').toLowerCase()) > -1 || isEncrypted || isSigned) {
                        items.splice(items.findIndex((item) => item.type === 'edit'), 1);
                    }
                    if (([INTERCOM].indexOf((mailType || '').toLowerCase()) > -1) || isEncrypted) {
                        items.splice(items.findIndex((item) => item.type === 'forward'), 1);
                    }
                    if ((!from || !from.email || !from.email.match(MAIL_REGEX)) || ([INTERCOM, VOICE, FAX].indexOf((mailType || '').toLowerCase()) > -1) || isEncrypted) {
                        items.splice(items.findIndex((item) => item.type === 'answer'), 1);
                        items.splice(items.findIndex((item) => item.type === 'answerAll'), 1);
                    }
                    return items;
                }
                return [];
            }
            case SITE: {
                const items = [{
                    onClick: async () => {
                        hideMenu();
                        if (typeof chayns !== 'undefined') {
                            const result = await chayns.dialog.input({
                                message: textStrings?.['txt_chayns_de_report_why'] || 'Warum möchtest Du diese Seite melden?',
                                inputType: chayns.dialog.inputType.TEXTAREA,
                                placeholderText: textStrings?.['txt_chayns_de_description'] || 'Beschreibung',
                                buttons: [{
                                    buttonType: 1,
                                    text: textStrings?.['txt_chayns_de_send'] || 'Senden',
                                }],
                            });

                            if (result.buttonType === 1) {
                                const { status } = await reportSite(site.siteId, result.text);
                                if (status === 204) {
                                    chayns.dialog.alert('', textStrings?.['txt_chayns_de_report_success'] || 'Vielen Dank für Deine Meldung. Wir werden diese Seite überprüfen.');
                                } else {
                                    chayns.dialog.alert('', textStrings?.['txt_chayns_de_report_error'] || 'Beim Melden der Seite ist ein Fehler aufgetreten. Wir wurden benachrichtigt und kümmern uns darum.');
                                }
                            }
                        }
                    },
                    icon: 'far fa-exclamation-triangle',
                    text: textStrings?.['txt_chayns_de_report'] || 'Melden',
                }];
                return items;
            }
            case SITE2:
                return [{
                    icon: 'fa fa-thumbtack',
                    text: 'Als App einrichten',
                    enabled: isMyChaynsApp,
                    onClick: () => {
                        appCall(59, {
                            name: sites.values[oldIndex].siteName,
                            url: `https://sub60.tobit.com/l/${sites.values[oldIndex].siteId}`,
                            siteId: sites.values[oldIndex].siteId
                        });
                    }
                }, {
                    icon: 'fa fa-trash',
                    text: 'Löschen',
                    enabled: true,
                    onClick:async () => {
                        const dialogHelper = new DialogHelper();
                        const {buttonType} = await dialogHelper.createDialog({
                            type: 'confirm',
                            text: `Soll die Seite ${sites.values[oldIndex].siteName} wirklich entfernt werden?`, buttons: [{
                                type: DialogButtonType.OK,
                                text: 'Ok'
                            }, {
                                type: DialogButtonType.CANCEL,
                                text: 'Abbrechen'
                            }]
                        }).open();

                        if(buttonType !== DialogButtonType.OK) return;

                        const copyLocations = locations.slice();
                        copyLocations.splice(oldIndex, 1);
                        dispatch(setSites({
                            ...sites,
                            values: copyLocations,
                        }));
                        dispatch(setDraggedSite(null));
                        dispatch(setIgnoreFocused(false));

                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        const { payload } = await dispatch(handleDeleteSite({
                            id: locations[oldIndex]?.id,
                            isMyChaynsApp,
                            sites: copyLocations,
                        }));

                        if (!payload) {
                            dispatch(setSites(sites));
                            chayns.dialog.toast({
                                description: 'Beim Verbergen der Site ist ein Fehler aufgetreten. Offline?',
                                type: 4,
                            });
                        }
                    }
                }].filter(x => x.enabled);
            default:
                return [];
        }
    };

    const checkParentNodes = (clickTarget, count = 10) => {
        if (!clickTarget) {
            return false;
        }
        if (clickTarget.classList.contains('menu-wrapper') || clickTarget.classList.contains('menu')) {
            return true;
        }
        if (count > 0 && clickTarget !== document.body) {
            return checkParentNodes(clickTarget.parentNode, count - 1);
        }
        return false;
    };

    useEffect(() => {
        const handleClick = (e) => {
            if (e && (!checkParentNodes(e.target) && (contextType !== SITE2 || !(e.target.className.includes('infocenter') && e.target.className.includes('active'))))) {
                if (active) {
                    hideMenu();
                }
            }
        };
        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextType, active]);

    if ((!x && !y) || !isClient) {
        return null;
    }

    const parent = document.querySelector('.color_scheme');

    return (
        <>
            <ChaynsContextMenu
                parent={parent || document.body}
                ref={contextMenuRef}
                onLayerClick={contextType === SITE2 ? () => () => {
                } : undefined}
                coordinates={{
                    x,
                    y,
                }}
                position={contextType === SITE2 ? 4 : undefined}
                disableDialog={contextType === SITE2}
                items={getItems()}
                className={classnames({
                    'dark-mode': colorMode === 'dark',
                })}
            />
            <style jsx global>
                {`
                    .cc__bubble {
                        z-index: 9999999;

                        .dark-mode {
                            color: white;

                            .context-menu__item:hover {
                                background-color: #585858 !important;
                            }
                        }
                    }

                    .context-menu-spacer {
                        position: relative;
                        margin-bottom: 10px;

                        &:before {
                            position: absolute;
                            bottom: -5px;
                            height: 1px;
                            background-color:  var(--chayns-color--003);
                            width: 100%;
                            left: 0;
                            content: '';
                        }
                    }
                `}
            </style>
        </>
    );
};

ContextMenu.propTypes = {
    position: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
    }),
};

ContextMenu.defaultProps = {
    position: null,
};

ContextMenu.displayName = 'ContextMenu';

export default ContextMenu;

import React, { useState, useEffect, memo } from 'react';
import classNames from 'classnames';

import { CSSTransition } from 'react-transition-group';
import { useSelector } from 'react-redux';
import { getAppState } from '../../redux-modules/app/selector';
import { getEnvState } from '../../redux-modules/env/selector';

const AppBanner: React.FC = () => {
    const app = useSelector(getAppState);
    const {
        colorMode,
    } = app;
    const isDarkMode = colorMode === 'dark';

    const env = useSelector(getEnvState);
    const {
        isAndroid,
        isMyChaynsApp,
    } = env;

    const [isAppMessageHidden, setIsAppMessageHidden] = useState(true);

    useEffect(() => {
        if (isAndroid && !isMyChaynsApp) {
            try {
                setIsAppMessageHidden(localStorage.getItem('hideAppMessage') === 'true');
            } catch (error) {
                // ignore
            }
        }
    }, [isAndroid, isMyChaynsApp]);

    return (
        <>
            <CSSTransition
                classNames="app_banner_animation"
                key="app_banner"
                timeout={500}
                in={!isAppMessageHidden}
                unmountOnExit
            >
                <div>
                    <div
                        className={classNames('app_banner', {
                            dark_mode: isDarkMode,
                        })}
                    >
                        <div
                            className="remove_icon"
                        >
                            <i
                                className="fal fa-times"
                                onClick={() => {
                                    try {
                                        localStorage.setItem('hideAppMessage', 'true');
                                    } catch (error) {
                                        // ignore
                                    }
                                    setIsAppMessageHidden(true);
                                }}
                            />
                        </div>
                        <img
                            src="https://sub60.tobit.com/l/60021-08989?size=72"
                            alt="chayns icon"
                            className="chayns_icon"
                        />
                        <div
                            className="app_info"
                        >
                            <p
                                className="chayns_text"
                            >
                                chayns®
                            </p>
                            <p
                                className="tobit_text"
                            >
                                Tobit®Software™
                            </p>
                        </div>
                        <div
                            className="app_center"
                        >
                            <a
                                href="https://play.google.com/store/apps/details?id=com.Tobit.android.Slitte60021089891"
                                onClick={() => {
                                    localStorage.setItem('hideAppMessage', 'true');
                                    setIsAppMessageHidden(true);
                                }}
                            >
                                Anzeigen
                            </a>
                        </div>
                    </div>
                </div>
            </CSSTransition>
            <style jsx>
                {`
                    .app_banner_animation {
                        &-enter {
                            transform: translateY(0);
                        }

                        &-enter-active {
                            transform: translateY(0);
                            transition: all 2.5s;
                        }

                        &-exit {
                            transform: translateY(0);
                            height: 64px;
                        }

                        &-exit-active, &-exit-done {
                            transform: translateY(-70px);
                            transition: transform .25s, height 0s .25s ;
                            height: 0;
                        }
                    }
    
                    .app_banner {
                        width: 100%;
                        display: flex;
                        background-color: white;
                        height: 64px;
                        border-bottom: 1px solid lightgray;
                        align-items: center;
                        flex-shrink: 0;

                        &.dark_mode {
                            border-bottom: 1px solid rgba(255, 255, 255, .15);
                            background-color: #1E1E1E;
                            color: white;
                        }

                        .remove_icon {
                            margin: 0 10px 0 22px;
                            width: 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            i {
                                height: 10px;
                                color: grey;
                            }
                        }

                        .chayns_icon {
                            width: 40px;
                            height: 40px;
                            margin: 10px;
                            border-radius: 5px;
                            border: 1px solid lightgrey;
                        }

                        .app_info {
                            .chayns_text {
                                margin: 0;
                            }

                            .tobit_text {
                                font-size: 85%;
                                opacity: .7;
                            }
                        }

                        .app_center {
                            display: flex;
                            text-align: right;
                            width: 100%;
                            justify-content: flex-end;
                            margin-right: 20px;

                            a {
                                text-decoration: underline;
                                border: none;
                            }
                        }
                    }
                `}
            </style>
        </>
    );
};

AppBanner.displayName = 'AppBanner';

export default memo(AppBanner);

import React from 'react';
import classNames from 'classnames';

const ChaynsLogo = ({colorMode, margin}) => {

    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="21"
                width="75.24"
                viewBox="0 0 104.385 30.6"
                className={classNames('chayns-header-logo', {
                    darkmode: true,
                })}
            >
                <path
                    d="M432.477,120.4,428.951,109H424.9l5.865,16.7-.542,1.448a3.378,3.378,0,0,1-1.136,1.738,3.662,3.662,0,0,1-2.136.511l-.712-.051v2.93a6.6,6.6,0,0,0,1.814.273q3.661,0,5.187-4.242l6.645-19.3h-4Z"
                    transform="translate(-365.351 -101.946)"
                />
                <path
                    d="M523.009,107.2a6.011,6.011,0,0,0-4.865,2.249l-.1-1.942h-2.475l-.085.222-.983,2.879v13.681h3.746V112.345a3.6,3.6,0,0,1,3.373-2.062,2.912,2.912,0,0,1,2.255.767,3.461,3.461,0,0,1,.695,2.368v10.887h3.746V113.214C528.247,109.193,526.484,107.2,523.009,107.2Z"
                    transform="translate(-439.763 -100.453)"
                />
                <path
                    d="M263.191,74.347a5.831,5.831,0,0,0-4.645,2.147V67.6H254.8V91.436h3.746V79.441a3.613,3.613,0,0,1,3.373-2.01,3.094,3.094,0,0,1,2.2.716,3.337,3.337,0,0,1,.746,2.453V91.436h3.746V80.464Q268.539,74.355,263.191,74.347Z"
                    transform="translate(-224.085 -67.6)"
                />
                <path
                    d="M358.163,112.74a5.314,5.314,0,0,0-1.78-4.157,6.932,6.932,0,0,0-4.662-1.482,8.576,8.576,0,0,0-3.492.682,6.183,6.183,0,0,0-2.475,1.874,4.155,4.155,0,0,0-.915,2.573h3.746a1.99,1.99,0,0,1,.814-1.636,3.278,3.278,0,0,1,2.085-.647,2.856,2.856,0,0,1,2.2.784,2.981,2.981,0,0,1,.729,2.079v1.09h-2.288a9.468,9.468,0,0,0-5.645,1.431,4.749,4.749,0,0,0-1.983,4.106A4.647,4.647,0,0,0,346.076,123a6.268,6.268,0,0,0,8.459-.443,6.349,6.349,0,0,0,.424,1.567h3.814v-.256a8.542,8.542,0,0,1-.627-3.595V112.74Zm-3.746,6.713a3.469,3.469,0,0,1-1.475,1.448,4.233,4.233,0,0,1-2.085.545,2.775,2.775,0,0,1-1.915-.647,2.205,2.205,0,0,1-.712-1.721,2.388,2.388,0,0,1,1.051-2.062,5.552,5.552,0,0,1,3.119-.733h2.017Z"
                    transform="translate(-298.579 -100.37)"
                />
                <path
                    d="M81.872,114.435h4A7.734,7.734,0,0,0,78.516,109H73.6v3.646h4.916A4.044,4.044,0,0,1,81.872,114.435Z"
                    transform="translate(-73.6 -101.946)"
                />
                <path
                    d="M78.616,177.389H73.7v3.646h4.916a7.728,7.728,0,0,0,7.357-5.435h-4A4.048,4.048,0,0,1,78.616,177.389Z"
                    transform="translate(-73.683 -157.199)"
                />
                <path
                    d="M175.6,175.7h-4a7.734,7.734,0,0,0,7.357,5.435h4.916v-3.646h-4.916A4.135,4.135,0,0,1,175.6,175.7Z"
                    transform="translate(-154.988 -157.282)"
                />
                <path
                    d="M171.6,114.435h4a4.052,4.052,0,0,1,3.356-1.772h4.916V109h-4.916A7.728,7.728,0,0,0,171.6,114.435Z"
                    transform="translate(-154.988 -101.946)"
                />
                <path
                    d="M86.2,149.423a1.82,1.82,0,0,0,1.814,1.823h20.9a1.823,1.823,0,0,0,0-3.646h-20.9A1.82,1.82,0,0,0,86.2,149.423Z"
                    transform="translate(-84.064 -133.97)"
                />
                <path
                    d="M670.912,191.1a1.721,1.721,0,1,0,1.712,1.721A1.734,1.734,0,0,0,670.912,191.1Zm0,3.135a1.414,1.414,0,1,1,1.407-1.414A1.407,1.407,0,0,1,670.912,194.235Z"
                    transform="translate(-568.239 -170.058)"
                />
                <path
                    d="M676.622,196.054a.543.543,0,0,0,.1-.341.578.578,0,0,0-.186-.46.806.806,0,0,0-.526-.153H675.3v2.028h.356v-.784h.39l.407.784h.373v-.017l-.458-.835A1.086,1.086,0,0,0,676.622,196.054Zm-.627.017h-.356v-.682h.373a.429.429,0,0,1,.271.085.354.354,0,0,1,.085.256.287.287,0,0,1-.1.239A.357.357,0,0,1,675.995,196.071Z"
                    transform="translate(-573.305 -173.377)"
                />
                <path
                    d="M615.1,121.035a3.012,3.012,0,0,1,.39.034,4.676,4.676,0,0,0,.254-1.567,4.094,4.094,0,0,0-.61-2.283,4.924,4.924,0,0,0-1.865-1.619,13.906,13.906,0,0,0-3.441-1.159,9.672,9.672,0,0,1-2.916-.971,1.55,1.55,0,0,1-.729-1.346,1.819,1.819,0,0,1,.78-1.533,3.354,3.354,0,0,1,2-.579,3.042,3.042,0,0,1,2.119.716,2.334,2.334,0,0,1,.8,1.806h3.729a4.739,4.739,0,0,0-1.831-3.851,7.426,7.426,0,0,0-4.814-1.482,7.116,7.116,0,0,0-4.611,1.448,4.463,4.463,0,0,0-1.814,3.612,4.258,4.258,0,0,0,2.8,3.936,14.357,14.357,0,0,0,3.187,1.022,9.072,9.072,0,0,1,2.729.92,1.71,1.71,0,0,1,.814,1.533,1.756,1.756,0,0,1-.763,1.482,3.742,3.742,0,0,1-2.238.562,3.856,3.856,0,0,1-2.441-.716,2.656,2.656,0,0,1-1-2.045H602a4.848,4.848,0,0,0,.9,2.794,5.967,5.967,0,0,0,2.492,2.045,8.55,8.55,0,0,0,3.644.75A8.808,8.808,0,0,0,613,123.71a2.326,2.326,0,0,1-.051-.494A2.144,2.144,0,0,1,615.1,121.035Z"
                    transform="translate(-512.43 -100.453)"
                />
            </svg>
            <style jsx>{`
              .chayns-header-logo {
                fill: #005eb8;
                margin-left: ${margin || 16}px;

                &.darkmode {
                  fill: #ffffff;
                }
              }`}</style>
        </>
    )
}

export default ChaynsLogo;

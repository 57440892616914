/* eslint-disable no-nested-ternary */
import React, { useState, useMemo } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import SmallWaitCursor from '@chayns-components/SmallWaitCursor';
import Icon from '@chayns-components/Icon';
import Input from '@chayns-components/Input';
import Button from '@chayns-components/Button';
import { AutoSizer, List } from 'react-virtualized';

import { useSelector } from 'react-redux';
import { getTextStringState } from '../../../../redux-modules/text-strings/selector';
import { getAppBottomBarHeight, getAppState } from '../../../../redux-modules/app/selector';
import TAPPIDS from '../../../../constants/tapp-ids';
import IncognitoIcon from '../incognito/Incognito';
import { getCookie, setCookie } from '../../../../utils/helper';
import { removeLoginHistory } from '../../../../api/token/post/removeLoginHistory';

const propTypes = {
    user: PropTypes.shape({
        personId: PropTypes.string,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        userId: PropTypes.number,
        token: PropTypes.string,
        incognito: PropTypes.bool,
    }).isRequired,
    mainUser: PropTypes.shape({
        personId: PropTypes.string,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        userId: PropTypes.number,
        token: PropTypes.string,
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
    managedAccounts: PropTypes.arrayOf(PropTypes.shape({
        child: PropTypes.string,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
    })).isRequired,
    onUserSwitch: PropTypes.func.isRequired,
    setShowMenu: PropTypes.func.isRequired,
};

const ManagedAccountsContent: React.FC<InferProps<typeof propTypes>> = ({
    isLoading,
    managedAccounts,
    user,
    onUserSwitch,
    mainUser,
    setShowMenu,
}) => {
    const app = useSelector(getAppState);
    const { colorMode } = app;
    const textStrings = useSelector(getTextStringState);

    const [searchString, setSearchString] = useState('');

    const getName = (account) => `${account.firstname || ''} ${account.lastName || ''}`;
    const getIncognitoName = (account) => getName({
        firstname: `${account.firstname?.charAt(0)}.`,
        lastName: 'Inkognito',
    });
    const isIncognitoShown = !searchString || getIncognitoName(mainUser).toLowerCase().includes(searchString.toLowerCase());

    const shownAccounts = useMemo(() => {
        if (!searchString) {
            return managedAccounts;
        }
        const lowerCaseSearch = searchString.toLowerCase();
        return managedAccounts.filter((account) => (`${account.firstname || ''} ${account.lastname || ''}`).toLowerCase().includes(lowerCaseSearch));
    }, [managedAccounts, searchString]);

    const isMainAccountShown = !searchString || `${mainUser.firstname || ''} ${mainUser.lastname || ''}`.toLowerCase().includes(searchString.toLowerCase());

    const onChange = (val: string) => {
        setSearchString(val);
    };

    const rowRenderer = ({ key, index, style }) => {
        if (index === 0 && isMainAccountShown) {
            return (
                <div
                    key={key}
                    style={style}
                    className="children-wrapper"
                    onClick={() => {
                        window.postMessage({
                            action: 23,
                            value: false,
                        }, '*');
                        if (user.incognito) {
                            setCookie('incognito', '', -1);
                        }

                        if (mainUser.personId !== user.personId || user.incognito) {
                            onUserSwitch(mainUser.personId, true, false);
                        }

                        setShowMenu(false);
                    }}
                >
                    <img
                        className="user-image"
                        src={`https://sub60.tobit.com/u/${mainUser.personId}?size=200`}
                        alt=""
                    />
                    {mainUser.personId === user.personId && !user.incognito
                        ? (
                            <b className="children-name">{`${mainUser.firstname || ''} ${mainUser.lastname || ''}`}</b>
                        ) : (
                            <div
                                className="children-name"
                            >
                                {`${mainUser.firstname || ''} ${mainUser.lastname || ''}`}
                            </div>
                        )}
                </div>
            );
        }

        if (index === 1 && isMainAccountShown && isIncognitoShown) {
            return (
                <div
                    key={key}
                    style={style}
                    className="children-wrapper incognito"
                    onClick={async () => {
                        window.postMessage({
                            action: 23,
                            value: true,
                        }, '*');

                        setCookie('incognito', 'true');
                        if (mainUser.personId !== user.personId || !user.incognito) {
                            await onUserSwitch(mainUser.personId, true, true);
                        }
                        setShowMenu(false);
                    }}
                >
                    <div className="children-name-wrapper">
                        <IncognitoIcon/>
                        {mainUser.personId === user.personId && user.incognito
                            ? (
                                <b className="children-name">{getIncognitoName(mainUser)}</b>
                            ) : (
                                <div className="children-name">{getIncognitoName(mainUser)}</div>
                            )}
                    </div>

                    {shownAccounts.length > 0 ? (
                        <div className="border-element">
                            <div className="border-element-middle"/>
                        </div>
                    ) : false}
                </div>
            );
        }

        const account = shownAccounts[isMainAccountShown && isIncognitoShown ? index - 2 : isMainAccountShown ? index - 1 : isIncognitoShown ? index - 1 : index];

        return (
            <div
                key={key}
                style={style}
                className="children-wrapper"
                onClick={() => {
                    window.postMessage({
                        action: 23,
                        value: false,
                    }, '*');

                    if (user.incognito) {
                        setCookie('incognito', '', -1);
                    }

                    if (user.personId !== account.child) {
                        onUserSwitch(account.child, false, false);
                    }
                    setShowMenu(false);
                }}
            >
                <img
                    className="user-image"
                    src={`https://sub60.tobit.com/u/${account.child}?size=200`}
                    alt=""
                />
                {user.personId === account.child
                    ? (
                        <b className="children-name">{`${account.firstname || ''} ${account.lastname || ''}`}</b>
                    ) : (
                        <div className="children-name">{`${account.firstname || ''} ${account.lastname || ''}`}</div>
                    )}
            </div>
        );
    };

    const token = useMemo(() => {
        if (user?.isGuardedAccountSelected) {
            return user.mainToken;
        }
        return user?.token;
    }, [user]);

    const appBarBottomHeight = useSelector(getAppBottomBarHeight);

    const longestName = [...shownAccounts, mainUser].reduce((acc, account) => (acc?.length > `${account.firstname || ''} ${account.lastname || ''}`.length ? acc : `${account.firstname || ''} ${account.lastname || ''}`), '');

    return (
        <>
            {isLoading && (
                <div
                    className="center-wait-cursor"
                >
                    <SmallWaitCursor
                        show
                    />
                </div>
            )}

            {!isLoading && (
                <>
                    <div style={{ height: 10 }}/>
                    {managedAccounts.length > 6 && (
                        <span onClick={(e) => {e.stopPropagation(); e.preventDefault();}}><Input
                            value={searchString}
                            onChange={onChange}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            design={Input.BORDER_DESIGN}
                            id="search"
                            iconLeft="fal fa-search"
                            placeholder={textStrings?.['txt_chayns_de_find'] || 'Finden'}
                            // eslint-disable-next-line react/jsx-no-useless-fragment
                            right={<></>}
                            style={{
                                margin: '0 10px',
                            }}
                        /></span>
                    )}

                    <AutoSizer disableHeight>
                        {({ width }) => {
                            let rowCount = shownAccounts.length;
                            if (isMainAccountShown && isIncognitoShown) {
                                rowCount = shownAccounts.length + 2;
                            } else if (isMainAccountShown) {
                                rowCount = shownAccounts.length + 1;
                            }

                            const height = Math.min(rowCount * 41, Math.min(330, window.innerHeight - appBarBottomHeight - 70 - 135)) + (isIncognitoShown ? 15 : 5);

                            return (
                                <List
                                    height={height}
                                    rowCount={rowCount}
                                    rowHeight={({ index }) => {
                                        if (index === 1 && isIncognitoShown) return 51;
                                        return 41;
                                    }}
                                    rowRenderer={rowRenderer}
                                    width={width}
                                    className="scrollbar"
                                    style={{
                                        maxWidth: '70vw'
                                    }}
                                />
                            );
                        }}
                    </AutoSizer>

                    {!isMainAccountShown && shownAccounts.length === 0 && (
                        <p
                            className="chayns__color--text"
                            style={{
                                textAlign: 'center',
                                marginTop: '10px',
                            }}
                        >
                            {textStrings?.['txt_chayns_de_no_results'] || 'Keine Ergebnisse'}
                        </p>
                    )}

                    {(isMainAccountShown || shownAccounts.length > 0) && longestName && (
                        <div
                            style={{
                                height: 0,
                                visibility: 'hidden',
                                userSelect: 'none',
                                paddingLeft: '14px',
                            }}
                            className="children-wrapper"
                        >
                            <img
                                className="user-image"
                                alt=""
                            />
                            <div className="children-name">{longestName}</div>
                        </div>
                    )}

                    {mainUser.personId === user.personId && (
                        <div
                            className="children-wrapper add-person"
                            onClick={() => {
                                setShowMenu(false);
                                chayns.selectTapp({ id: TAPPIDS.CHAYNS_ID, params: 'accordion=guardeddata' });
                            }}
                        >
                            <div
                                className="icon-wrapper"
                            >
                                <Icon
                                    icon="far fa-plus"
                                />
                            </div>
                            <div>
                                {textStrings?.['txt_chayns_de_add'] || 'Hinzufügen'}
                            </div>
                        </div>
                    )}

                    <div
                        className="logout-wrapper"
                        style={{
                            backgroundColor: colorMode === 'light' ? 'rgb(194, 194, 194, .2)' : '',
                        }}
                    >
                        <Button
                            onClick={async () => {
                                const dialogResult = await chayns.dialog.select({
                                    message: textStrings?.['txt_chayns_de_logout_confirm_dialog'],
                                    list: [{
                                        value: 1,
                                        name: textStrings?.['txt_chayns_de_remove_from_history'],
                                    }],
                                });
                                if (dialogResult.buttonType === 1) {
                                    if (dialogResult.selection?.length) {
                                        const deviceId = getCookie('login_deviceId');
                                        removeLoginHistory(deviceId, token).then(() => {
                                            chayns.logout();
                                        }).catch(() => {
                                            chayns.logout();
                                        });
                                    } else {
                                        chayns.logout();
                                    }
                                }
                            }}
                        >
                            {textStrings?.['txt_chayns_de_logout'] || 'Abmelden'}
                        </Button>
                    </div>
                </>
            )}

            <style jsx global>
                {`
                  .switch-chayns-id {
                    margin: 0;
                  }

                  .center-wait-cursor {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 160px;
                    height: 50px;
                  }

                  .managed_accounts {
                    .ReactVirtualized__Grid__innerScrollContainer {
                      margin-top: 5px;
                    }
                  }

                  .children-wrapper {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    padding: 3px 11px;

                    &.incognito {
                      flex-direction: column;
                      align-items: start;
                      padding: 0;
                    }

                    .children-name-wrapper {
                      display: flex;
                      align-items: center;
                      padding: 3px 11px;
                      width: 100%;

                      &:hover {
                        background-color: var(--chayns-color--secondary-103);
                      }
                    }

                    .border-element {
                      display: flex;
                      justify-content: center;
                      height: 10px;
                      align-items: center;

                      .border-element-middle {
                        right: 0;
                        left: 0;
                        height: 1px;
                        position: absolute;
                        border-bottom: 1px solid var(--chayns-color--002)
                      }
                    }

                    &:not(.incognito):hover {
                      background-color: var(--chayns-color--secondary-103);
                    }

                    &.add-person {
                      margin-top: 15px;

                      .icon-wrapper {
                        width: 35px;
                        height: 35px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid;
                        border-radius: 50%;
                        margin-right: 10px;
                        opacity: 0.7;

                        :global(.react-chayns-icon) {
                          font-size: 20px;
                        }
                      }
                    }
                  }

                  .children-name {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                  }

                  .user-image:not(.no-margin) {
                    border-radius: 50%;
                    width: 35px;
                    height: 35px;
                    margin-right: 10px;
                    border: 0.05px solid rgba(0, 0, 0, 0.1);
                    flex-shrink: 0;
                    box-sizing: border-box;
                    color: var(--chayns-color--003);

                    &.incognito svg {
                      border: 2px solid transparent;
                      border-radius: 50%;
                      color: rgb(192, 192, 192);
                    }

                  }

                  .logout-wrapper {
                    display: flex;
                    justify-content: center;
                    margin-top: 10px;
                    padding: 10px 0;
                  }
                `}
            </style>
        </>
    );
};

ManagedAccountsContent.displayName = 'ManagedAccountsContent';

ManagedAccountsContent.propTypes = propTypes;

export default ManagedAccountsContent;

import throttle from 'lodash.throttle';

export default class WebSocketHandler {
    connected = false;

    connectedPromise = null;

    authenticationSucceeded = false;

    socket = null;

    lastPing = null;

    checkConnectionInterval = null;

    topics = {
        folder: null,
        mail: null,
    };

    toRegister = {
        folder: null,
        mail: null,
    };

    davidSiteId = null;

    retries = 0;

    openSocket = null;

    webSocketEvents = null;

    connectionError = null;

    serverUrl = null;

    registerFolderEvents = 'subject,hasComments,from.id,from.name,from.email,isRead,createdDateTime,receivedDateTime,importance,id,isCc,hasAttachments,properties.isAnswered,properties.isReminder,properties.isRedirected,properties.isSigned,properties.isEncrypted,type,isMuted,previewImageUrl';

    constructor(webSocketEvents: (e: unknown) => void, connectionError: (davidSiteId: string) => void, openSocket: (davidSiteId: string) => void) {
        this.webSocketEvents = webSocketEvents;
        this.connectionError = connectionError;
        this.openSocket = openSocket;
    }

    initWebSocket = (reconnect: boolean, init?: boolean): void => {
        if (this.connected) return;
        if (!this.serverUrl) return;

        const webSocket = new WebSocket(`${this.serverUrl.replace('https', 'wss')}_ws`);

        this.connectedPromise = new Promise<void>((resolve, reject) => {
            webSocket.onopen = () => {
                if (!init) {
                    this.openSocket(this.davidSiteId);
                }
                this.connected = true;
                this.socket = webSocket;
                resolve();
                this.sendSocketMessage(`bearer ${window.ChaynsInfo?.User.TobitAccessToken || chayns.env.user.tobitAccessToken}`);
                this.checkConnectionInterval = setInterval(this.checkConnection, 10000);
            };
            webSocket.onerror = () => {
                this.handleError();
                reject();
            };
        });

        webSocket.onmessage = (event) => {
            this.addEvent({
                direction: 'IN',
                message: event.data,
            });
            if (event.data === 'ping' || event.data === 'Okay') {
                this.lastPing = +new Date();
            }

            if (event?.data === 'Authentication succeeded.') {
                this.authenticationSucceeded = true;
                if (reconnect) {
                    if (this.topics.folder) {
                        this.connectToFolder();
                    }
                    if (this.topics.mail) {
                        this.connectToMail();
                    }
                } else {
                    this.register();
                }
            }
        };

        webSocket.onclose = (event) => {
            if (!event.wasClean) {
                throttle(() => {
                    this.connectionError(this.davidSiteId);
                }, 15 * 1000, {
                    leading: true,
                });
            }
            clearInterval(this.checkConnectionInterval);
            this.connected = false;
            this.socket = null;
            this.toRegister = {
                folder: null,
                mail: null,
            };
            this.authenticationSucceeded = false;
        };

        this.socket = webSocket;
    };

    handleError = (): void => {
        if (this.socket) {
            this.socket.close();
        }
        this.socket = null;
        clearInterval(this.checkConnectionInterval);
        this.authenticationSucceeded = false;
        this.lastPing = null;
        this.connected = false;
        if (this.retries < 1) {
            this.initWebSocket(true);
            this.retries += 1;
        } else if (this.retries < 20) {
            setTimeout(() => {
                this.initWebSocket(true);
                this.retries += 1;
            }, 10000);
        } else {
            setTimeout(() => {
                this.initWebSocket(true);
                this.retries += 1;
            }, 60000);
        }
    };

    disconnect = (): void => {
        clearInterval(this.checkConnectionInterval);
        this.authenticationSucceeded = false;
        if (this.socket) {
            this.socket.close();
        }
        this.lastPing = null;
        this.connected = false;
        this.topics = {
            folder: null,
            mail: null,
        };
        this.toRegister = {
            folder: null,
            mail: null,
        };
    };

    connectToFolder = (): void => {
        if (this.topics.folder) {
            this.sendSocketMessage(JSON.stringify({
                action: 'register',
                topic: this.topics.folder,
                select: this.registerFolderEvents,
            }));
        }
    };

    connectToMail = (): void => {
        if (this.topics.mail) {
            this.sendSocketMessage(JSON.stringify({
                action: 'register',
                topic: this.topics.mail,
                select: 'comments',
            }));
        }
    };

    setServerUrl = (serverUrl: string, davidSiteId: string): void => {
        if (serverUrl && serverUrl !== this.serverUrl) {
            this.disconnect();
        }
        this.retries = 0;
        this.serverUrl = serverUrl;
        this.davidSiteId = davidSiteId;
    };

    sendSocketMessage = (message: string): void => {
        this.socket.send(message);

        this.addEvent({
            direction: 'OUT',
            message,
        });
    };

    register = (): void => {
        if (this.authenticationSucceeded && this.connected) {
            if (this.toRegister.folder !== this.topics.folder) {
                if (this.topics.folder) {
                    this.sendSocketMessage(JSON.stringify({
                        action: 'unregister',
                        topic: this.topics.folder,
                    }));
                }
                if (this.toRegister.folder) {
                    this.sendSocketMessage(JSON.stringify({
                        action: 'register',
                        topic: this.toRegister.folder,
                        select: this.registerFolderEvents,
                    }));
                }
                this.topics.folder = this.toRegister.folder;
                this.toRegister.folder = null;
            }
            if (this.topics.mail !== this.toRegister.mail) {
                if (this.topics.mail) {
                    this.sendSocketMessage(JSON.stringify({
                        action: 'unregister',
                        topic: this.topics.mail,
                    }));
                }
                if (this.toRegister.mail) {
                    this.sendSocketMessage(JSON.stringify({
                        action: 'register',
                        topic: this.toRegister.mail,
                        select: 'comments',
                    }));
                }
                this.topics.mail = this.toRegister.mail;
                this.toRegister.mail = null;
            }
        }
    };

    addEvent = async (event: { time?: Date, message: string, topic?: string, type?: string, folderId?: string, davidSiteId?: string, direction?: 'IN' | 'OUT' }): Promise<void> => {
        if (!event) return;

        const e = event;
        e.time = new Date();
        try {
            const obj = JSON.parse(e.message);
            e.message = obj;
            e.topic = obj.topicId;
        } catch (ex) {
            // ignore
        }
        e.type = this.topics.folder === event.topic ? 'folder' : 'mail';
        e.folderId = this.topics.folder;
        e.davidSiteId = this.davidSiteId;
        this.webSocketEvents(e);
    };

    handleTopics = async (state: { folderId?: string, id?: string }): Promise<void> => {
        await this.connectedPromise;
        if (this.connected) {
            if (this.authenticationSucceeded) {
                if (this.topics.folder !== state?.folderId) {
                    if (!this.topics.folder) {
                        this.topics.folder = state.folderId;
                        this.sendSocketMessage(JSON.stringify({
                            action: 'register',
                            topic: state.folderId,
                            select: this.registerFolderEvents,
                        }));
                    }
                }

                if (this.topics.mail === state?.id) return;

                if (state?.id) {
                    if (!this.topics.mail) {
                        this.topics.mail = state.id;
                        this.sendSocketMessage(JSON.stringify({
                            action: 'register',
                            topic: state.id,
                            select: 'comments',
                        }));
                    } else {
                        this.sendSocketMessage(JSON.stringify({
                            action: 'unregister',
                            topic: this.topics.mail,
                        }));
                        this.sendSocketMessage(JSON.stringify({
                            action: 'register',
                            topic: state.id,
                            select: 'comments',
                        }));
                        this.topics.mail = state.id;
                    }
                }
            } else {
                if (!this.topics.folder || this.topics.folder !== this.toRegister.folder) {
                    this.toRegister.folder = state.folderId;
                }
                if (!this.topics.mail || this.topics.mail !== this.toRegister.mail) {
                    this.toRegister.mail = state.id;
                }
            }
        } else {
            clearInterval(this.checkConnectionInterval);
            this.authenticationSucceeded = false;
            this.davidSiteId = null;
            this.lastPing = null;
            this.connected = false;
            this.initWebSocket(false);
        }
    };

    checkConnection = (): void => {
        if (this.lastPing && +new Date() - this.lastPing >= 35000) {
            this.handleError();
        }
    };
}

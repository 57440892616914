import React, { memo, useMemo } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import MailItemDate from '../../messages/item-date/ItemDate';
import UserImage from '../../messages/user-image/UserImage';
import { NEWS } from '../../../../../../constants/types';
import { MOBILE_VIEW_BREAKPOINT } from '../../../../../../constants/height';
import { getAppState } from '../../../../../../redux-modules/app/selector';
import { setSelectedItem } from '../../../../../../redux-modules/app/actions';
import { NewsItem } from '../../../../../../redux-modules/news/actions';
import { Location } from '../../../../../../types/location';

type NewsItemTypes = {
    isMyChaynsApp: boolean,
    item: NewsItem,
    sites: Array<Location>,
};

const NewsItemComponent: React.FC<NewsItemTypes> = ({
    item,
    sites,
    isMyChaynsApp,
}) => {
    const dispatch = useDispatch();

    const app = useSelector(getAppState);
    const {
        isChaynsReady,
        width,
        colorMode,
        selectedItem,
    } = app;

    const {
        headline,
        locationName,
        message,
        creationTime,
        locationId,
        siteId,
        description,
        publishTime,
        id,
    } = item;

    const isSelected = id === selectedItem?.id && selectedItem?.type === NEWS;

    const parsed = useMemo(() => (message || description) ? new DOMParser().parseFromString(message || description, 'text/html').documentElement.textContent : "", [message, description]);

    return (
        <>
            <div
                className={classNames('news-item', {
                    selected: isSelected,
                    'mobile-view': width < MOBILE_VIEW_BREAKPOINT,
                    'is-darkmode': colorMode === 'dark',
                })}
                onClick={() => {
                    if (isChaynsReady) {
                        if (isMyChaynsApp) {
                            const chaynsCall = {
                                action: 142,
                                value: {
                                    siteId,
                                    color: null,
                                    colorMode: null,
                                    tappIdUrl: `/tapp/index/91958?tid=${id}`,
                                },
                            };
                            chayns.invokeCall(chaynsCall);
                        } else {
                            dispatch(setSelectedItem({
                                ...item,
                                type: NEWS,
                            }));
                        }
                    }
                }}
            >
                <div
                    className="news-item-header"
                >
                    <div
                        className="news-item-header-left"
                    >
                        <UserImage
                            color={sites?.find((site) => site.siteId === siteId)?.color}
                            url={`https://sub60.tobit.com/l/${locationId}?size=114`}
                            isSquare
                        />
                        <div
                            className="news-item-info"
                        >
                            <div
                                className="news-item-info-title"
                            >
                                {headline || locationName}
                            </div>
                            <div
                                className="news-item-bottom"
                            >
                                <div
                                    className="news-item-info-subtitle"
                                >
                                    {parsed}
                                </div>
                                <div
                                    className="news-item-header-right"
                                >
                                    <MailItemDate
                                        date={publishTime || creationTime}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <style jsx>
                {`
                    .news-item {
                        height: 64px;
                        overflow: hidden;
                        cursor: pointer;
                        width: 100%;
                        user-select: none;
                        
                        &-header {
                            padding: 12px 16px 12px;
                            display: flex;
                            justify-content: space-between;

                            &-left {
                                display: flex;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                z-index: 1;
                                width: 100%;

                                .news-image {
                                    pointer-events: none;
                                    display: block;
                                    height: 40px;
                                    min-width: 40px;
                                    margin-right: 10px;
                                    background-position: 50%;
                                    background-size: cover;
                                    object-fit: scale-down;
                                    max-width: 40px;
                                }

                                .news-item-info {
                                    flex: 1 1;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    min-width: 1px;

                                    &-title {
                                        overflow: hidden;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        line-height: 1.3rem;
                                    }

                                    &-subtitle {
                                        font-size: 85%;
                                        opacity: .6;
                                        overflow: hidden;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        margin-right: 3px;
                                    }
                                }
                            }
                            &-right {
                                z-index: 1;
                                display: flex;
                                align-items: center;
                            }
                        }

                        &:before {
                            content: "";
                            z-index: 0;
                            width: 0;
                            height: 100%;
                            position: absolute;
                            background-color: var(--mouseOverColor);
                            opacity: 0;
                            transform: scaleX(1) translateX(0);
                            will-change: transform, opacity;
                        }

                        &.selected {
                            &:before {
                                opacity: 1;
                                background-color: var(--mouseOverColor);
                                transform: scaleX(200) translateX(30%);
                                width: 100%;
                            }
                        }

                        .news-item-bottom {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }

                        &.mobile-view {
                            .news-item-header {
                                padding: 12px 22px;
                            }
                        }
                    }
                `}
            </style>
        </>
    );
};

NewsItemComponent.displayName = 'NewsItem';

export default memo(NewsItemComponent);

/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useDispatch } from 'react-redux';
import TAPPIDS from '../../../../constants/tapp-ids';
import postTobitAccessTokenForm from '../../../../utils/postTobitAccessToken';
import { setIsScannerShown, setIsMenuShown } from '../../../../redux-modules/app/actions';

type IFrameTypes = {
    tappId: number,
    tappIframeWrapperRef: React.LegacyRef<HTMLDivElement>,
    postTobitAccessToken: boolean,
    isActive: boolean,
    overlayActive: boolean,
    isTappAlreadyLoaded: boolean,
    isMobileBrowser: boolean,
    isMyChaynsApp: boolean,
    tappUrl: string,
    height: number,
};

const IFrame: React.FC<IFrameTypes> = ({
    tappId,
    tappIframeWrapperRef,
    postTobitAccessToken,
    isActive,
    overlayActive,
    isTappAlreadyLoaded,
    isMobileBrowser,
    isMyChaynsApp,
    tappUrl,
    height,
    style
}) => {
    const dispatch = useDispatch();

    const iFrameStyles = useMemo(() => {
        if (!isActive) {
            return {
                zIndex: '0',
                pointerEvents: 'none',
            } as React.CSSProperties;
        }
        const tempStyles = {
            zIndex: '100',
            padding: '',
            pointerEvents: 'auto',
        } as React.CSSProperties;
        if (overlayActive) {
            tempStyles.pointerEvents = 'none';
        }
        return tempStyles;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, overlayActive, height, tappId, isTappAlreadyLoaded]);

    useEffect(() => {
        if (postTobitAccessToken) {
            postTobitAccessTokenForm(tappUrl, window.chaynsInfo.User.TobitAccessToken, 'TobitAccessToken', tappId > 0 ? tappId : tappId * -1);
        }
    }, [tappUrl, tappId, postTobitAccessToken]);

    return (
        <>
            <div
                className={classNames('tapp-iframe-wrapper', 'scrollbar', {
                    scrollable: isMobileBrowser && tappId !== TAPPIDS.LANDING_PAGE,
                    'not-active': !isActive,
                })}
                ref={tappIframeWrapperRef}
                onClick={() => {
                    if (overlayActive) {
                        dispatch(setIsScannerShown(false));
                        dispatch(setIsMenuShown(false));
                    }
                }}
            >
                <iframe
                    className={classNames('tapp-iframe', {
                        desktop: !isMobileBrowser && !isMyChaynsApp,
                        'login-tapp': tappId === TAPPIDS.LANDING_PAGE,
                        mobile_browser: isMobileBrowser,
                    })}
                    frameBorder="0"
                    marginHeight={0}
                    marginWidth={0}
                    id={`TappIframe_${tappId > 0 ? tappId : tappId * -1}`}
                    name={`TappIframe_${tappId > 0 ? tappId : tappId * -1}`}
                    src={postTobitAccessToken ? undefined : tappUrl}
                    style={style || iFrameStyles}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    allowpaymentrequest="true"
                />
            </div>
            <style jsx>
                {`
                    .tapp-iframe-wrapper {
                        &.not-active {
                            height: 0;
                            min-height: 0 !important;
                        }
                    }
                `}
            </style>
        </>
    );
};

IFrame.propTypes = {
    tappId: PropTypes.number.isRequired,
    postTobitAccessToken: PropTypes.bool,
    isActive: PropTypes.bool.isRequired,
    overlayActive: PropTypes.bool,
    isTappAlreadyLoaded: PropTypes.bool.isRequired,
    isMobileBrowser: PropTypes.bool.isRequired,
    isMyChaynsApp: PropTypes.bool.isRequired,
    tappUrl: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
};

IFrame.defaultProps = {
    postTobitAccessToken: false,
    overlayActive: false,
};

IFrame.displayName = 'IFrame';

export default memo(IFrame);

import React, { memo, useEffect, useState, useCallback } from 'react';
import { ReactSortable } from 'react-sortablejs';
import classnames from 'classnames';
import SmallWaitCursor from '@chayns-components/SmallWaitCursor';

import {useDispatch, useSelector} from 'react-redux';
import { MOBILE_VIEW_BREAKPOINT } from '../../../../../constants/height';
import ErrorBoundary from '../../../../error-boundary/ErrorBoundary';
import SearchResults from '../search-results/SearchResults';
import NoResults from '../no-results/NoResults';
import Suggestions from './suggestions/Suggestions';
import { getEnvState } from '../../../../../redux-modules/env/selector';
import { Location } from '../../../../../types/location';
import Site from '../site/Site';
import {setContextMenu} from "../../../../../redux-modules/context-menu/actions";
import {SITE, SITE2} from "../../../../../constants/types";
import {getAppState} from "../../../../../redux-modules/app/selector";

type SitesTappContentTypes = {
    callbackfn: (location: Location) => void,
    firefox: boolean,
    list: () => void,
    locations: Array<Location>,
    mobileBrowser: boolean,
    myChaynsApp: boolean,
    noResults: boolean,
    onChoose: ({ oldIndex: number }) => void,
    onEnd: ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => Promise<void>,
    onMove: (e: HTMLDivElement) => boolean,
    onUnchoose: () => void,
    searchError: boolean,
    searchString: string,
    isMenuShown: boolean,
    isScannerShown: boolean,
    siteWidth: string,
    textStrings: { [key: string]: string },
    width: number,
    suggestions: Array<Location>,
    isClient: boolean,
};

const SitesTappContent: React.FC<SitesTappContentTypes> = ({
    draggedSite,
    firefox,
    list,
    locations,
    mobileBrowser,
    myChaynsApp,
    noResults,
    onChoose,
    onEnd,
    onMove,
    onUnchoose,
    searchError,
    searchString,
    isMenuShown,
    isScannerShown,
    siteWidth,
    textStrings,
    width,
    suggestions,
    isClient,
}) => {
    const dispatch = useDispatch();
    const env = useSelector(getEnvState);
    const {
        isMyChaynsApp,
        isIOS,
    } = env;

    const getLocationData = useCallback(() => {
        let updatedLocations = locations;
        updatedLocations = updatedLocations.filter((s) => s);

        return updatedLocations;
    }, [isMyChaynsApp, locations]);

    const [shownLocations, setShownLocations] = useState(getLocationData());

    useEffect(() => {
        setShownLocations(getLocationData());
    }, [getLocationData, locations]);

    const showWaitCursor = !suggestions && (!shownLocations || shownLocations.length === 0);

    const handleChoose = ({ oldIndex, item }) => {
        if(searchString || !item){
            return onChoose({ oldIndex });
        }

        const { left, top, height, width } = item.getBoundingClientRect();
        dispatch(setContextMenu({
            x: left + (width/2) + 3,
            y: top + window.scrollY + (height/2) -60 + 5,
            id: shownLocations[oldIndex].siteId,
            contextType: SITE2,
            oldIndex
        }));
        return onChoose({ oldIndex });
    };

    const handleMove = (arg) => {
        dispatch(setContextMenu({
            id: null,
            x: null,
            y: null,
            contextType: null,
        }));
        return onMove(arg);
    }

    return (
        <>
            {!searchString && (
                <>
                    <div>
                        <ReactSortable
                            forceFallback={!firefox}
                            list={shownLocations}
                            animation={200}
                            delayOnTouchOnly={false}
                            delay={400}
                            touchStartThreshold={5}
                            easing="cubic-bezier(1, 0, 0, 1)"
                            onEnd={onEnd}
                            onChoose={handleChoose}
                            onEndEvent={onEnd}
                            onUnchoose={onUnchoose}
                            onMove={handleMove}
                            setList={list}
                            ghostClass="sortable-ghost"
                            filter={isIOS && !isMyChaynsApp ? '' : '.disable_drag'}
                            style={{
                                maxWidth: width >= MOBILE_VIEW_BREAKPOINT ? '1000px' : undefined,
                                pointerEvents: isScannerShown || isMenuShown ? 'none' : 'all',
                                padding: width < MOBILE_VIEW_BREAKPOINT ? '0px 10px 20px' : '40px 43px 30px',
                                marginBottom: myChaynsApp || mobileBrowser ? '0' : '100px',
                                flexWrap: 'wrap',
                                display: 'grid',
                                justifyContent: 'space-between',
                                margin: 'auto',
                                marginTop: (width >= MOBILE_VIEW_BREAKPOINT && !isMyChaynsApp) ? undefined : 15,
                            }}
                            className={classnames('sites_list', {
                                'chayns__color--text': myChaynsApp,
                            })}
                            scroll
                        >
                            {shownLocations.map((site, i) => {
                                if (!site.type || site.type !== 'create') {
                                    return (
                                        <ErrorBoundary
                                            key={`${site.siteId}-${site.pageId || ''}`}
                                            section="site"
                                        >
                                            <Site
                                                name={site.siteName}
                                                color={site.siteColor || site?.locationMetadata?.color}
                                                domain={site.domain}
                                                siteId={site.siteId}
                                                isPage={!!site.pageId}
                                                pageId={site.pageId}
                                                pageIcon={site.pageMeta?.icon}
                                                pageName={site.pageMeta?.name}
                                                isKnown
                                                isDraggingFirefox={!!(draggedSite && firefox)}
                                                isClient={isClient}
                                                isMyChaynsApp={isMyChaynsApp}
                                                searchString={searchString}
                                                oldIndex={i}
                                                index={i}
                                            />
                                        </ErrorBoundary>
                                    );
                                }
                            })}
                        </ReactSortable>

                        {suggestions && (
                            <Suggestions
                                myChaynsApp={myChaynsApp}
                                width={width}
                                suggestions={suggestions}
                                isScannerShown={isScannerShown}
                                isMenuShown={isMenuShown}
                                mobileBrowser={mobileBrowser}
                                siteWidth={siteWidth}
                                searchString={searchString}
                                isClient={isClient}
                            />
                        )}

                        {showWaitCursor && (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <SmallWaitCursor
                                    show
                                />
                            </div>
                        )}
                    </div>
                    <div
                        style={{
                            height: width < MOBILE_VIEW_BREAKPOINT ? '20px' : null,
                        }}
                    />
                </>
            )}

            {searchString && (
                <ErrorBoundary
                    section="searchResults"
                >
                    <SearchResults/>
                </ErrorBoundary>
            )}

            {noResults && (
                <NoResults/>
            )}

            {searchError && searchString && (
                <p
                    className={classnames('error_text', {
                        'chayns__color--texti': myChaynsApp,
                    })}
                >
                    {textStrings?.['txt_chayns_de_offline'] || 'Bei dem Laden der Daten ist ein Fehler aufgetreten. Offline?'}
                </p>
            )}
            <style jsx global>
                {`
                  @media all and (max-width: 500px) {
                    .sites_list {
                      grid-template-columns: repeat(auto-fill, minmax(86px, 1fr));
                    }
                  }
                  @media all and (max-width: 800px) and (min-width: 501px) {
                    .sites_list {
                      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
                    }
                  }
                  @media all and (min-width: 801px){
                    .sites_list {
                      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
                    }
                  }
            `}
            </style>
        </>
    );
};

SitesTappContent.displayName = 'SitesTappContent';

export default memo(SitesTappContent);

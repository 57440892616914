/* eslint-disable prefer-destructuring */
/* eslint-disable no-bitwise */

const decodeBase64 = (base64: string): string => {
    const text = atob(base64);
    const length = text.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
        bytes[i] = text.charCodeAt(i);
    }
    const decoder = new TextDecoder(); // default is utf-8
    return decoder.decode(bytes);
};

export const decodeTobitAccessToken = (tobitAccessToken: string): {
    exp: number,
    PersonID: string,
    TobitUserID: number,
    FirstName: string,
    LastName: string,
    FacebookUserID?: string,
} => {
    if (tobitAccessToken && typeof (tobitAccessToken) === 'string' && tobitAccessToken.length > 0) {
        const spl = tobitAccessToken.split('.');
        if (spl.length === 3) {
            try {
                spl[1] = spl[1].slice(0, spl[1].length + (spl[1].length % 4));
                spl[1] = spl[1].replace(/-/g, '+').replace(/_/g, '/');

                return JSON.parse(decodeBase64(spl[1]));
            } catch (e) {
                //
            }
        }
    }
    return null;
};

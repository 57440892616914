import React, { Component, ErrorInfo } from 'react';
import logger from "../../constants/logger";
import Error from "../../pages/_error";
import ReloadView from "./ReloadView";

type ErrorBoundaryProps = {
    section: string,
    showErrorPage?: boolean,
};

type ErrorBoundaryState = {
    hasError: boolean,
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(): ErrorBoundaryState {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        const { section } = this.props;
        logger().error({
            message: 'Failed to render component!',
            section: 'ErrorBoundary/componentDidCatch',
            data: {
                section,
                error,
                errorInfo,
            },
        }, {
            message: error && error.message,
            stackTrace: errorInfo && errorInfo.componentStack,
        });
    }

    render(): React.ReactNode {
        const { children, showErrorPage = false } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            if (showErrorPage) {
                return <ReloadView/>;
            }
            return <div/>;
        }

        return children;
    }
}

export default ErrorBoundary;

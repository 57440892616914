import React from 'react';

const IncognitoIcon = () => (
    <div className="user-image incognito">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256 256"
            xmlSpace="preserve"
        >
            <g>
                <path
                    fill="currentColor"
                    d="M117.4,173c7.1-3,13.9-2.1,20.7,1.2c0.3-0.5,0.5-0.9,0.7-1.3c5.2-10.7,15.9-16.4,27.9-15c10.8,1.3,20.2,10.1,22.4,21   c2.3,11.3-2.7,22.8-12.8,28.5c-9.2,5.2-18.6,5.1-27.6-0.6c-8.8-5.6-12.9-13.9-12.4-24.4c0.1-2.1-0.2-2.8-2.3-2.9   c-4.8-0.2-9.6-0.1-14.6-0.1c1.2,9-1,16.9-7.2,23.4c-4.6,4.8-10.3,7.4-16.9,8c-12.7,1.3-24.9-7.4-28.1-20   c-3.2-12.8,3.2-25.8,15.2-31.1C94.6,154.5,110.2,158.8,117.4,173z"
                />
                <path
                    fill="currentColor"
                    d="M163.6,44.8c0,0-15.9,4.1-35.6,4.1s-30.8-4.1-30.8-4.1l-26.9,88.9h115.5L163.6,44.8z"
                />
                <path
                    fill="currentColor"
                    d="M228.2,142.4H27.8c-2.5,0-4.5,2-4.5,4.5v0c0,2.5,2,4.5,4.5,4.5h200.3c2.5,0,4.5-2,4.5-4.5v0   C232.7,144.4,230.6,142.4,228.2,142.4z"
                />
            </g>
        </svg>
    </div>
);

export default IncognitoIcon;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedItem } from '../../../../../../redux-modules/app/actions';
import { getAppState } from '../../../../../../redux-modules/app/selector';
import ApiV5Tapp from '../../../ApiV5Tapp/ApiV5Tapp';
import TAPPIDS from '../../../../../../constants/tapp-ids';
import { IntercomThreadViewSystem } from '../../../../../../constants/defaults';
import appCall from '../../../../../../utils/appCall';
import { getIntercomThreadByIdState } from '../../../../../../redux-modules/intercom/selector';

const SelectedIntercom: React.FC = () => {
    const dispatch = useDispatch();
    const {
        selectedItem,
    } = useSelector(getAppState);

    const thread = useSelector(getIntercomThreadByIdState(selectedItem.id));

    return (
        <>
            <div
                className="infocenter-intercom-tapp"
            >
                <ApiV5Tapp
                    customData={{
                        thread,
                        goBackToInfocenter: () => {
                            appCall(292, {
                                show: true,
                            }, {
                                awaitResult: false,
                            });
                            dispatch(setSelectedItem(null));
                        },
                    }}
                    type="client-module"
                    tappId={TAPPIDS.INTERCOM}
                    system={IntercomThreadViewSystem}
                />
            </div>
            <style jsx global>
                {`
                      .infocenter-intercom-tapp {
                        height: 100%;
                        padding: 0 !important;
                        
                        > div {
                          height: 100%;
                          > div:not(.module-css) {
                            height: 100%;
                          }
                        }
                      }
                    `}
            </style>
        </>
    );
};
SelectedIntercom.displayName = 'SelectedIntercom';

export default SelectedIntercom;

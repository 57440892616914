import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import classNames from 'classnames';

import { useSelector } from 'react-redux';
import Dialog from './dialog/Dialog';
import { getEnvState } from '../../redux-modules/env/selector';

type DialogViewTypes = {
    inProp: boolean,
};

const DialogView: React.FC<DialogViewTypes> = ({
    inProp,
}) => {
    const env = useSelector(getEnvState);
    const {
        isMobile,
        isTablet,
    } = env;

    const classnames = classNames({
        'mobile-dialog-animation': isMobile || isTablet,
        'dialog-animation': !isMobile && !isTablet,
    });

    return (
        <CSSTransition
            classNames={classnames}
            unmountOnExit
            mountOnEnter
            appear
            in={inProp}
            timeout={200}
        >
            <>
                <div className="dialog-transition">
                    <Dialog/>
                </div>
                <style jsx global>
                    {`
                    .dialog-animation {
                        &-enter {
                            opacity: 0;
                            transition: opacity .2s ease-out;
                        }
                    
                        &-enter-active {
                            opacity: 1;
                        }
                    
                        &-exit {
                            opacity: 1;
                            transition: opacity .2s ease-out;
                        }
                    
                        &-exit-active {
                            opacity: 0;
                        }
                    }
                    
                    .mobile-dialog-animation {
                        &-enter {
                            opacity: 0;
                            transform: translateY(20px);
                            transition: opacity .4s, transform .2s ease-out;
                        }
                    
                        &-enter-active {
                            opacity: 1;
                            transform: translateY(0);
                        }
                    
                        &-exit {
                            opacity: 1;
                            transform: translateY(0);
                            transition: opacity .4s, transform .2s ease-out;
                        }
                    
                        &-exit-active {
                            transform: translateY(20px);
                            opacity: 0;
                        }
                    }
                
                    .dialog-transition {
                        height: 100%;
                        z-index: 1300;
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 70px;
                        right: 0;
                    }
                `}
                </style>
            </>
        </CSSTransition>
    );
};

DialogView.displayName = 'DialogView';

DialogView.propTypes = {
    inProp: PropTypes.bool,
};

DialogView.defaultProps = {
    inProp: false,
};

export default memo(DialogView);

import { updateUserData } from './chayns-info';
import { closeWindow, setTobitAccessToken } from '../calls/json-native-calls/calls';
import TAPPIDS from '../constants/tapp-ids';
import { remove } from './url-parameter';
import { invalidateToken, getToken, AT_COOKIE_NAME, RT_COOKIE_NAME } from './TokenHelper';

export function showLogin(force): void {
    if (!window.chaynsInfo.User?.TobitAccessToken) {
        chayns.selectTapp({ id: TAPPIDS.LANDING_PAGE });
    } else if(force) {
        window.openLoginOverlay(true);
    }
}

export function login(fromBroadcast = false, teamLogin): void {
    if (!fromBroadcast) {
        const event = new CustomEvent('login', {
            detail: {
                token: getToken(AT_COOKIE_NAME)?.token,
            },
        });
        window.dispatchEvent(event);
    }

    Promise.all([updateUserData(false), closeWindow()]).then(() => {
        remove('forcelogin');
        if (typeof window !== 'undefined' && window.loggedIn) {
            window.loggedIn(true, undefined, teamLogin);
        }
    });
}

export function logout(fromBroadcast = false): void {
    if (typeof window === 'undefined' || typeof chaynsInfo === 'undefined') return;

    const at = getToken(AT_COOKIE_NAME)?.token;
    const rt = getToken(RT_COOKIE_NAME)?.token;

    if (!fromBroadcast) {
        const event = new Event('logout');
        window.dispatchEvent(event);
    }

    setTobitAccessToken('');
    Promise.all([invalidateToken(at), invalidateToken(rt), updateUserData(false), closeWindow()]).then(() => {
        if (typeof window.loggedIn === 'function') {
            window.loggedIn(false);
        }
    });
    if (navigator.userAgent.includes('VrAhausDigital')) {
        window.vuplex.postMessage({ type: 'logout' });
    }
}

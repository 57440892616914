import nodeCache from '../../utils/nodeCache';
import logger from '../../constants/logger';

const loadTextStrings = async (lang: string) : Promise<{ status: number, result?: {[key: string]: string}}> => {
    try {
        const url = `https://webapi.tobit.com/TextStringService/v1.0/LangStrings/chayns-de?language=${lang}`;
        const value = nodeCache.get(url) as { status: number, result?: {[key: string]: string}};

        if (value) {
            return value;
        }

        const response = await fetch(url);

        if (response.status === 200) {
            const result = await response.json();

            nodeCache.set(url, {
                status: response.status,
                result,
            }, 60 * 15);

            return {
                status: response.status,
                result,
            };
        }
        return {
            status: response.status,
        };
    } catch (ex) {
        logger().warning({
            message: 'Failed to load textStrings',
            section: 'api/text-strings/index.js',
        }, ex);
        return {
            status: 500,
        };
    }
};

export { loadTextStrings };

import React, { useCallback, useEffect } from 'react';
import Input from '@chayns-components/Input';
import { useDispatch, useSelector } from 'react-redux';

import { setIsSearchingMessages, setMessageSearchString } from '../../../../../../redux-modules/app/actions';
import { getTextStringState } from '../../../../../../redux-modules/text-strings/selector';
import { getAppState } from '../../../../../../redux-modules/app/selector';
import PropTypes from "prop-types";
import {filterThreads, handleLoadIntercom} from "../../../../../../redux-modules/intercom/actions";

const MessageSearch = ({ infocenter }) => {
    const dispatch = useDispatch();
    const app = useSelector(getAppState);
    const {
        messageSearchString
    } = app;

    const textStrings = useSelector(getTextStringState);

    useEffect(() => {
        const loadMessages = () => {
            dispatch(filterThreads(messageSearchString));
            if (!messageSearchString) {
                dispatch(setIsSearchingMessages(false));
                return;
            }

            if (messageSearchString.trim().length > 0) {
                dispatch(handleLoadIntercom({
                    token: chaynsInfo.User.TobitAccessToken,
                    searchString: messageSearchString,
                }));
            } else {
                dispatch(setIsSearchingMessages(false));
            }
        };

        const timeoutId = setTimeout(() => {
            loadMessages();
        }, 500);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [dispatch, messageSearchString]);

    const onChange = useCallback((value) => {
        dispatch(setMessageSearchString(value));
        if (value) {
            dispatch(setIsSearchingMessages(true));
        }
    }, [dispatch]);

    useEffect(() => {
        const searchIcon = document.querySelector('.input-group__icon');
        const handleIconClick = () => {
            const input: HTMLInputElement = document.querySelector('.message-input input');
            if (input) {
                input.focus();
            }
        };

        if (searchIcon) {
            searchIcon.addEventListener('click', handleIconClick);
        }

        return () => {
            if (searchIcon) {
                searchIcon.removeEventListener('click', handleIconClick);
            }
        };
    }, []);

    return (
        <>
            <div
                style={{
                    margin: infocenter ? '21px 16px 13px' : '10px 16px 5px'
                }}
            >
                <Input
                    value={messageSearchString}
                    onChange={onChange}
                    icon="fal fa-search"
                    placeholder={textStrings?.['txt_chayns_de_find'] || 'Finden'}
                    className="message-input"
                />
            </div>
            <style jsx global>
                {`
                    .message-input {
                        border-bottom: none;
                        box-sizing: border-box;
                      
                      input {
                        color: ${infocenter ? 'white !important' : ''};
                      }

                        .input-group__icon {
                            opacity: 1 !important; 
                            font-size: 14px;
                          color: ${infocenter ? 'white !important' : ''};
                        }

                        ~ label {
                            font-size: 21px !important;
                            opacity: 1 !important;
                            color: ${infocenter ? 'white !important' : 'var(--chayns-color-text) !important'};
                            top: unset !important;
                        }
                    }
                `}
            </style>
        </>
    );
};

MessageSearch.propTypes = {
    infocenter: PropTypes.bool,
};

MessageSearch.defaultProps = {
    infocenter: false,
};

MessageSearch.displayName = 'MessageSearch';

export default MessageSearch;

import React from 'react';

const ChaynsLogoWhite = () => {

    return (
        <>
            <img src="chayns_logo_white.svg"/>
            <style jsx>
                {`
                    img {
                        position:absolute;
                        top: 0;
                        left: 0;
                      right:0;
                        bottom:0;
                      margin: auto;
                      width: 50%;
max-width: 242px;
                      
                    }
                `}
            </style>
        </>
    )
}

export default ChaynsLogoWhite;

import React, { memo, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import TappBtn from '../tappBtn/TappBtn';
import { setActiveSmartClientTapp, setActiveTapp } from '../../../redux-modules/app/actions';
import { getActiveDavidSiteId, getActiveSmartClientTapp, getActiveTapp } from '../../../redux-modules/app/selector';
import TAPPIDS from '../../../constants/tapp-ids';
import { getTappsState } from "../../../redux-modules/tapps/selector";

const propTypes = {
    active: PropTypes.bool.isRequired,
    activeColor: PropTypes.string.isRequired,
    isDarkMode: PropTypes.bool.isRequired,
    iconColor: PropTypes.string.isRequired,
    tappsRendered: PropTypes.number.isRequired,
};

const SmartClientBar: React.FC<InferProps<typeof propTypes>> = ({
    active,
    activeColor,
    isDarkMode,
    iconColor,
    tappsRendered,
}) => {
    const dispatch = useDispatch();

    const activeSmartClientTapp = useSelector(getActiveSmartClientTapp);
    const activeDavidSiteId = useSelector(getActiveDavidSiteId);
    const allTapps = useSelector(getTappsState);
    const activeTapp = useSelector(getActiveTapp);

    const tapps = useMemo(() => {
        return allTapps.find(x => x.id === TAPPIDS.SMART_CLIENT)?.children?.map(x => ({
            tappId: x.identifier,
            icon: x.icon.value,
            name: x.showNames.de
        })) || []
    }, [allTapps]);

    useEffect(() => {
        if (active && typeof window.handleSetSelection === 'function') {
            window.handleSetSelection({
                folderId: activeSmartClientTapp,
                davidSiteId: activeDavidSiteId,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSmartClientTapp]);

    return (
        <>
            <div
                className={classNames('smart-client-bar', {
                    active,
                })}
            >
                {tapps.map((t) => (
                    <TappBtn
                        key={t.tappId}
                        tapp={t}
                        handleTappClick={() => {
                            dispatch(setActiveTapp(TAPPIDS.SMART_CLIENT));
                            dispatch(setActiveSmartClientTapp(t.tappId));
                        }}
                        style={{
                            width: `${100 / tappsRendered}%`,
                        }}
                        activeColor={activeColor}
                        isDarkMode={isDarkMode}
                        iconColor={iconColor}
                        isSmartClientTapp
                        active={(t.tappId === activeSmartClientTapp && activeTapp === TAPPIDS.SMART_CLIENT)}
                    />
                ))}

            </div>
            <style jsx>
                {`
                    .smart-client-bar {
                        max-height: 0;
                        height: 100%;
                        display: flex;
                        overflow: hidden;
                        transition: all .3s;
                        justify-content: center;

                        &.active {
                            max-height: 65%;
                        }
                    }
                `}
            </style>
        </>
    );
};

SmartClientBar.propTypes = propTypes;

export default memo(SmartClientBar);

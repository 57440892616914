import { useEffect, useCallback, useRef, useState } from 'react';

import throttle from 'lodash.throttle';
import { updateMails } from '../../api/smart-client/get/graphQuerys';
import WebSocketHandler from '../smart-client/websocketHandler';
import addOnActivateListener from '../addOnActivateListener';
import { handleLoadData, handleLoadMailData, receivedComments, setDeletedMails, setMailCount, setMails } from '../../redux-modules/smart-client/actions';
import TAPPIDS from '../../constants/tapp-ids';
import { MAIL } from '../../constants/types';
import { SmartClientServer } from '../../types/smartClient';
import { Tapp } from '../../types/tapp';
import { SelectedItem } from '../../types/selectedItem';
import { User } from '../../types/user';
import { setActiveDavidSiteId, setSelectedItem } from '../../redux-modules/app/actions';
import { setTapps } from '../../redux-modules/tapps/actions';

type SmartClient = {
    dispatch: (value: unknown) => void,
    server: Array<SmartClientServer>,
    tapps: Array<Tapp>,
    activeDavidSiteId: string,
    isSmartClientAvailable: boolean,
    isClient: boolean,
    isUserAuthenticated: boolean,
    selectedItem: SelectedItem,
    isChaynsReady: boolean,
    isMyChaynsApp: boolean,
    user: User,
};

const useSmartClient = ({
    server,
    tapps,
    activeDavidSiteId,
    isSmartClientAvailable,
    isClient,
    isUserAuthenticated,
    selectedItem,
    isChaynsReady,
    isMyChaynsApp,
    user,
    dispatch,
}: SmartClient) : void => {
    const [websocketAvailable, setWebsocketAvailable] = useState(false);
    const webSocketHandler = useRef(null);

    const handleUpdateMails = useCallback(async (davidSiteId = activeDavidSiteId) => {
        const currentServer = server?.find((s) => s.davidSiteId === davidSiteId);
        if (currentServer?.emailConnection) {
            const { emailConnection } = currentServer;
            const { status, result } = await updateMails(currentServer.endpoint, emailConnection.lastFetch as string, user?.token);
            if (status === 200 && result?.data) {
                const { emailConnection: newEmailConnection, folder } = result.data;
                dispatch(setMails({ emailConnection: { ...newEmailConnection, lastFetch: new Date(), count: folder.unreadItemCount } }));
            }
        }
    }, [activeDavidSiteId, dispatch, server, user]);

    const connectionError = useCallback((davidSiteId) => {
        handleUpdateMails(davidSiteId);
    }, [handleUpdateMails]);

    const openSocket = useCallback((davidSiteId) => {
        throttle(() => {
            handleUpdateMails(davidSiteId);
        }, 15 * 1000, {
            leading: true,
        });
    }, [handleUpdateMails]);

    const webSocketEvents = useCallback(({
        message,
        type,
        davidSiteId,
    }) => {
        const currentServer = server?.find((s) => s.davidSiteId === davidSiteId);
        if (message && currentServer) {
            const {
                unreadCount,
                entries,
                operation,
                topicId,
            } = message;

            if (typeof unreadCount === 'number' || entries) {
                dispatch(setMailCount(unreadCount || 0));
            }

            // mail updated
            if (type === 'folder') {
                // created or updated mail
                if ((!operation || operation === 'updated') && entries) {
                    const newMails = entries.map((mail: { id: string, properties: { [key:string]: string } }) => {
                        const {
                            id,
                            properties,
                        } = mail;
                        if (id && properties) {
                            const newMail = {
                                ...properties,
                                from: {
                                    name: properties['from.name'],
                                    id: properties['from.id'],
                                    email: properties['from.email'],
                                },
                                properties: {
                                    isAnswered: properties['properties.isAnswered'],
                                    isReminder: properties['properties.isReminder'],
                                    isRedirected: properties['properties.isRedirected'],
                                    isEncrypted: properties['properties.isEncrypted'],
                                    isSigned: properties['properties.isSigned'],
                                },
                            };
                            delete newMail['from.name'];
                            delete newMail['from.id'];
                            delete newMail['from.email'];
                            delete newMail['properties.isAnswered'];
                            delete newMail['properties.isReminder'];
                            delete newMail['properties.isRedirected'];
                            delete newMail['properties.isEncrypted'];
                            delete newMail['properties.isSigned'];
                            return newMail;
                        }
                        return null;
                    });
                    dispatch(setMails({ emailConnection: { values: newMails } }));
                } else if (operation === 'deleted' && entries) {
                    dispatch(setDeletedMails(entries));
                }
            } else if (type === MAIL) {
                // comments
                const newComments = entries?.map((comment: { id: string, properties: { [key:string]: string } }) => {
                    const {
                        id,
                        properties,
                    } = comment;
                    if (!properties.commentType) {
                        if (id && properties) {
                            const newComment = {
                                ...properties,
                                author: {
                                    name: properties['author.name'],
                                    id: properties['author.id'],
                                    email: properties['author.email'],
                                },
                            };
                            delete newComment['author.name'];
                            delete newComment['author.id'];
                            delete newComment['author.email'];
                            return newComment;
                        }
                        return null;
                    }
                    dispatch(handleLoadMailData({
                        mailId: topicId,
                        endpoint: currentServer.endpoint,
                    }));
                    return null;
                });
                const comments = newComments?.filter((comment: { id: string, properties: { [key:string]: string } }) => comment);
                if (comments?.length > 0) {
                    dispatch(receivedComments({
                        comments,
                        mailId: topicId,
                    }));
                }
            }
        }
    }, [dispatch, server]);

    useEffect(() => {
        window.setDavidSiteId = (davidSiteId: { retVal: { davidSiteId: string }}) => {
            const id = (() => {
                if (typeof davidSiteId === 'object') {
                    return davidSiteId.retVal.davidSiteId;
                }
                return davidSiteId;
            })();

            dispatch(setActiveDavidSiteId(id));

            const ts = Date.now() + (60 * 1000 * 60 * 24 * 30);
            const expires = new Date(ts).toUTCString();
            document.cookie = `davidSiteId=${id}; expires=${expires}; path=/`;

            if (selectedItem) {
                dispatch(setSelectedItem(null));
            }
            if (id !== activeDavidSiteId) {
                const activeServer = server?.length > 0 && server.find((s) => s.davidSiteId === id);

                if (activeServer) {
                    if (!activeServer.fetchedData) {
                        dispatch(handleLoadData(activeServer));
                    } else {
                        handleUpdateMails(id);
                    }
                }
            }
        };
    }, [activeDavidSiteId, handleUpdateMails, dispatch, selectedItem, server]);

    useEffect(() => {
        // register davidSiteId change in chayns app
        if (isChaynsReady && isMyChaynsApp) {
            chayns.invokeCall(JSON.stringify({
                action: 270,
                value: {
                    callback: 'window.setDavidSiteId',
                },
            }));
        }
    }, [isChaynsReady, isMyChaynsApp]);

    useEffect(() => {
        if (isSmartClientAvailable && server && websocketAvailable && isChaynsReady) {
            const currentServer = server?.find((s) => s.davidSiteId === activeDavidSiteId);
            if (currentServer && webSocketHandler.current) {
                webSocketHandler.current.setServerUrl(currentServer?.endpoint, currentServer?.davidSiteId);
                webSocketHandler.current.initWebSocket(false);
                const mailId = selectedItem?.type === MAIL ? selectedItem.id : null;
                webSocketHandler.current.handleTopics({
                    folderId: 'inbox',
                    id: mailId,
                });
            }
        }
    }, [isChaynsReady, isSmartClientAvailable, activeDavidSiteId, server, websocketAvailable, selectedItem]);

    useEffect(() => {
        if (isClient && isSmartClientAvailable && !websocketAvailable) {
            webSocketHandler.current = new WebSocketHandler(webSocketEvents, connectionError, openSocket);
            setWebsocketAvailable(true);
        }
    }, [connectionError, isClient, isSmartClientAvailable, openSocket, webSocketEvents, websocketAvailable, activeDavidSiteId]);

    useEffect(() => {
        dispatch(setMails({ emailConnection: { values: [] } }));
    }, [dispatch, selectedItem]);

    useEffect(() => {
        const currentServer = server?.find((s) => s.davidSiteId === activeDavidSiteId);
        if (currentServer?.emailConnection) {
            const { count } = currentServer.emailConnection;
            const isCountEqual = tapps.find((tapp) => tapp.tappId === TAPPIDS.SMART_CLIENT)?.badge === count;
            if (isCountEqual) {
                return;
            }
            const updatedTapps = tapps.map((tapp) => {
                if (tapp.tappId === TAPPIDS.SMART_CLIENT) {
                    return {
                        ...tapp,
                        badge: count || 0,
                    };
                }
                return tapp;
            });
            dispatch(setTapps(updatedTapps));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDavidSiteId, dispatch, server]);

    useEffect(() => {
        const cb = (isVisible: boolean) => {
            if (isVisible && isSmartClientAvailable) {
                handleUpdateMails();
            }
        };
        return addOnActivateListener(cb);
    }, [handleUpdateMails, isSmartClientAvailable]);

    useEffect(() => {
        setWebsocketAvailable(false);
    }, [isUserAuthenticated]);
};

export default useSmartClient;

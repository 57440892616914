/* eslint-disable @typescript-eslint/no-explicit-any */
import { setUser } from '../redux-modules/user/actions';
import parseUserData from './parseUserData';
import {
    setIsUserAuthenticated,
    resetState,
    setActiveTapp,
    setFadeIn,
    setLoginOverlay, setNoDelay
} from '../redux-modules/app/actions';
import TAPPIDS from '../constants/tapp-ids';
import ROUTES from '../constants/routes';
import { removeAllJsonCallListener } from '../calls/json-chayns-call/calls/utils/jsonCallListeners';
import { loadTapps } from './chayns-info';
import { handleLoadSites, setIsLoadingSites } from '../redux-modules/sites/actions';
import { handleLoadNews } from '../redux-modules/news/actions';
import {handleLoadUserData} from "../api/user";
import { handleLoadIntercom } from "../redux-modules/intercom/actions";
import {setTextStrings} from "../redux-modules/text-strings/actions";
import {loadTextStrings} from "../api/text-string";
import {language} from "./getLanguage";

type Props = {
    dispatch: (action: any) => any,
    params: {
        [key: string]: string
    },
    hasUserLoggedIn: boolean,
    isGuardedAccountSelected: boolean,
    handleSetTapps: (hasUserLoggedIn: boolean, newTappId: number) => void,
    isMyChaynsApp: boolean
};

const handleLogin = async ({
    dispatch,
    params,
    hasUserLoggedIn,
    isGuardedAccountSelected,
    handleSetTapps,
    isMyChaynsApp,
    teamLogin
}: Props) => {
    const $frame = document.querySelector('iframe.login-tapp');
    if($frame) {
        $frame.style.backgroundImage = 'unset';
    }
    if(hasUserLoggedIn) {
        // document.querySelector('.top-bar-wrapper').style.opacity = 0;
        // document.querySelector('.inner-content-wrapper').style.opacity = 0;
        dispatch(setLoginOverlay(false));
        dispatch(setNoDelay(true));
        dispatch(setFadeIn(false));

        setTimeout(() => {
            dispatch(setNoDelay(false));
        }, 500);

        setTimeout(() => {
            dispatch(setFadeIn(true));
        }, 1500);
    } else {
        dispatch(setNoDelay(true));
        dispatch(setFadeIn(true));
    }
    removeAllJsonCallListener();
    dispatch(setUser(parseUserData(isGuardedAccountSelected)));

    let shouldReload = false;
    if (hasUserLoggedIn) {
        const { result: userResult } = await handleLoadUserData(window.chaynsInfo.User.TobitAccessToken);
        dispatch(setUser(userResult));

        if (userResult.language && userResult.language !== window.chaynsInfo.language) {
            chaynsInfo.language = userResult.language;
            language.value = userResult.language;
            window.chaynsDeLanguage = userResult.language;
            shouldReload = true;
        }

        await loadTapps();
        dispatch(handleLoadIntercom({ token: window.chaynsInfo.User.TobitAccessToken }));
    }

    dispatch(setIsUserAuthenticated(hasUserLoggedIn));

    const currentRoute = window.location.pathname.replace(/^(\/)/, '');
    let tappAfterLogin = TAPPIDS.SITES;
    const knownPathId = ROUTES.tappRoutes[currentRoute];
    if (params.tapp) {
        tappAfterLogin = parseInt(params.tapp, 10);
    } else if (knownPathId) {
        tappAfterLogin = knownPathId;
    } else {
        const tapp = window.chaynsInfo.Tapps.find((t) => (t.customUrl ?? `tapp/${t.id}`) === currentRoute);
        if (tapp) {
            tappAfterLogin = tapp.id;
        }
    }

    dispatch(resetState());
    const newActive = teamLogin ? TAPPIDS.LOGIN_COMPLETED : hasUserLoggedIn ? tappAfterLogin : TAPPIDS.LANDING_PAGE;
    if(hasUserLoggedIn) {
        // document.querySelector('.top-bar-wrapper').style.opacity = 0;
        // document.querySelector('.inner-content-wrapper').style.opacity = 0;
        dispatch(setFadeIn(false));
    }
    dispatch(setActiveTapp(newActive));

    if (hasUserLoggedIn) {
        dispatch(setIsLoadingSites(true));
        dispatch(handleLoadSites({ isMyChaynsApp })).then((newSites: { payload: { values: any }; }) => {
            dispatch(setIsLoadingSites(false));
            if (newSites?.payload?.values?.length > 0) {
                dispatch(handleLoadNews({
                    sites: newSites.payload.values,
                }));
            }
        }).catch(() => {
            dispatch(setIsLoadingSites(false));
        });
    }

    handleSetTapps(hasUserLoggedIn, teamLogin ? TAPPIDS.LOGIN_COMPLETED : hasUserLoggedIn ? tappAfterLogin : TAPPIDS.LANDING_PAGE);

    if (typeof chayns !== 'undefined') {
        await chayns.getGlobalData();
    }
    if (shouldReload) {
        const { result } = await loadTextStrings(chaynsInfo.language);
        dispatch(setTextStrings(result));
    }
};

export default handleLogin;

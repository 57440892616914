/* eslint-disable react/prop-types */
import React from 'react';
import Accordion from '@chayns-components/Accordion';

import Site from '../../site/Site';
import ErrorBoundary from '../../../../../error-boundary/ErrorBoundary';
import { MOBILE_VIEW_BREAKPOINT } from '../../../../../../constants/height';
import { Location } from '../../../../../../types/location';

type SuggestionsTypes = {
    myChaynsApp: boolean,
    isMenuShown: boolean,
    isScannerShown: boolean,
    siteWidth: string,
    width: number,
    isClient: boolean,
    searchString: string,
    mobileBrowser: boolean,
    suggestions: Array<Location>,
};

const Suggestions: React.FC<SuggestionsTypes> = ({
    suggestions,
    myChaynsApp,
    width,
    isScannerShown,
    isMenuShown,
    siteWidth,
    searchString,
    isClient,
    mobileBrowser,
}) => (
    <>
        <Accordion
            head="Vorschläge für Dich"
            defaultOpened
            className="suggestions-accordion"
        >
            <div
                className="suggestions-content"
            >
                {suggestions.map((site) => (
                    <ErrorBoundary
                        key={site.siteId}
                        section="suggestion"
                    >
                        <Site
                            key={site.siteId}
                            name={site.name || site.siteName}
                            color={site.color || site?.locationMetadata?.color}
                            domain={site.domain}
                            siteId={site.siteId}
                            isKnown={false}
                            isClient={isClient}
                            isMyChaynsApp={myChaynsApp}
                            searchString={searchString}
                        />
                    </ErrorBoundary>
                ))}
            </div>
        </Accordion>
        <style jsx global>
            {`
                .suggestions-accordion {
                    max-width: ${width >= MOBILE_VIEW_BREAKPOINT ? '1000px' : undefined};
                    pointer-events: ${isScannerShown || isMenuShown ? 'none' : 'all'};
                    margin: auto;
                    margin-top: 20px;
                    padding: ${myChaynsApp || mobileBrowser ? '0 10px' : '0 43px'};

                  @media all and (max-width: 500px) {
                    .suggestions-content {
                      grid-template-columns: repeat(auto-fill, minmax(86px, 1fr));
                    }
                  }
                  @media all and (max-width: 800px) and (min-width: 501px) {
                    .suggestions-content {
                      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
                    }
                  }
                  @media all and (min-width: 801px){
                    .suggestions-content {
                      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
                    }
                  }
                  
                    .suggestions-content {
                        flex-wrap: wrap;
                        display: grid;
                        justify-content: space-between;
                        margin-top: 20px;
                    }
                }
            `}
        </style>
    </>
);

Suggestions.propTypes = {

};

export default Suggestions;

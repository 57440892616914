import React, { useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTappById } from '../../utils/tapp/custom-tapp';
import { setSelectedTapp } from '../../utils/chayns-info';
import TAPPIDS from '../../constants/tapp-ids';

import TappContent from './tapp-content/TappContent';
import { getAppState } from '../../redux-modules/app/selector';
import { getEnvState } from '../../redux-modules/env/selector';
import { getUserState } from '../../redux-modules/user/selector';
import { getTappsState } from '../../redux-modules/tapps/selector';
import { updateTapp, setTapps } from '../../redux-modules/tapps/actions';
import { LOGIN_TAPP_LIVE_URL, LOGIN_TAPP_STAGING_URL } from '../../constants/defaults';
import { isPRODUCTION } from '../../constants/environments';
import { setActiveTapp } from '../../redux-modules/app/actions';
import { Tapp } from '../../types/tapp';
import Infocenter from "../team-app-infocenter/Infocenter";

const TappComponent: React.FC = () => {
    const dispatch = useDispatch();

    const app = useSelector(getAppState);
    const {
        activeTapp,
        isClient,
        isScannerShown,
        isChaynsReady,
        isMenuShown,
        isUserAuthenticated,
        colorMode,
        iframePointerEvents
    } = app;

    const env = useSelector(getEnvState);
    const {
        isDesktop,
    } = env;

    const user = useSelector(getUserState);

    const tapps = useSelector(getTappsState);
    const usedTapps = isUserAuthenticated ? tapps?.filter((tapp) => tapp.id !== TAPPIDS.LANDING_PAGE) || [] : [{
        id: TAPPIDS.LANDING_PAGE,
        source: `${isPRODUCTION ? LOGIN_TAPP_LIVE_URL : LOGIN_TAPP_STAGING_URL}${isDesktop ? '&desktop=1' : ''}&colormode=${colorMode === 'dark' ? '1' : '2'}`,
    }];
    useEffect(() => {
        const tapp = getTappById(activeTapp);
        if (tapp) {
            setSelectedTapp(tapp as Tapp);
            if (activeTapp !== TAPPIDS.SITES) {
                const findTapp = tapps.find((t) => t.tappId === activeTapp);
                if (!findTapp?.wasLoaded) {
                    dispatch(updateTapp({ ...tapp, ...findTapp, wasLoaded: true }));
                }
            }
        }
    }, [activeTapp, dispatch, tapps]);

    useEffect(() => {
        if (isChaynsReady) {
            window.setActiveTapp(activeTapp);
        }
    }, [activeTapp, isChaynsReady]);

    useEffect(() => {
        const handleSetActiveTapp = ({ detail }: CustomEvent) => {
            if (detail.tappId) {
                if (detail.params) {
                    const updatedTapps = tapps.map((tapp) => {
                        if (tapp.id === detail.tappId) {
                            return {
                                ...tapp,
                                params: detail.params,
                            };
                        }
                        return tapp;
                    });
                    dispatch(setTapps(updatedTapps));
                }
                dispatch(setActiveTapp(detail.tappId));
            }
        };

        window.addEventListener('set-active-tapp', handleSetActiveTapp);

        return () => {
            window.removeEventListener('set-active-tapp', handleSetActiveTapp);
        };
    }, [dispatch, tapps]);

    if (activeTapp === TAPPIDS.INFOCENTER) return <Infocenter/>;
    return (
        <>
            <div className="tapp-wrapper">

                {usedTapps.map((tapp) => {
                    if ((isClient && (tapp.wasLoaded || tapp.id === TAPPIDS.LANDING_PAGE)) || (tapp.id === TAPPIDS.CALENDAR && activeTapp === tapp.id)) {
                        const url = tapp.id === TAPPIDS.INTERCOM ? tapp.source.replace('overview', 'full-view') : tapp.source;
                        return (
                            <TappContent
                                key={tapp.id}
                                isActive={tapp.id === activeTapp}
                                url={url}
                                tappId={tapp.id}
                                overlayActive={isMenuShown || isScannerShown || iframePointerEvents}
                                isTappAlreadyLoaded={!!tapps.find((openedTapp) => openedTapp.id === tapp.id)}
                                bundleUrl={tapp.bundleUrl}
                                postTobitAccessToken={user && tapp.type === 4}
                                params={tapp.params}
                                apiVersion={tapp.apiVersion}
                            />
                        );
                    }
                    return null;
                })}
                {isUserAuthenticated && (
                    <TappContent
                        key={TAPPIDS.SITES}
                        isActive={TAPPIDS.SITES === activeTapp}
                        tappId={TAPPIDS.SITES}
                        overlayActive={isMenuShown || isScannerShown || iframePointerEvents}
                    />
                )}
            </div>

            <style jsx>{`
                .tapp-wrapper {
                    height: 100%
                }`}
            </style>
        </>
    );
};

TappComponent.displayName = 'Tapp';

export default memo(TappComponent);

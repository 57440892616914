import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { AutoSizer, InfiniteLoader, List, WindowScroller } from 'react-virtualized';
import throttle from 'lodash.throttle';
import SmallWaitCursor from '@chayns-components/SmallWaitCursor';
import { useDispatch, useSelector } from 'react-redux';
import tobitEmojione from 'tobit-emojione';
import PropTypes from 'prop-types';
import MessageSearch from './message-search/MessageSearch';
import IntercomItem from './intercom-item/IntercomItem';
import { MOBILE_VIEW_BREAKPOINT } from '../../../../../constants/height';
import { getAppState } from '../../../../../redux-modules/app/selector';
import { getEnvState } from '../../../../../redux-modules/env/selector';
import { getTextStringState } from '../../../../../redux-modules/text-strings/selector';
import { getUserState } from '../../../../../redux-modules/user/selector';
import { getIntercomState } from '../../../../../redux-modules/intercom/selector';
import { handleLoadIntercom } from '../../../../../redux-modules/intercom/actions';

const Messages = ({ infocenter }) => {
    const dispatch = useDispatch();
    const app = useSelector(getAppState);
    const {
        colorMode,
        width,
        messageSearchString
    } = app;

    const env = useSelector(getEnvState);
    const {
        isMyChaynsApp,
    } = env;

    const textStrings = useSelector(getTextStringState);
    const messagesRef = useRef(null);
    const [lastSkip, setLastSkip] = useState(0);

    const user = useSelector(getUserState);

    const intercom = useSelector(getIntercomState);

    const showLoadingAnimation = !intercom.fetchedData && !intercom.hasError;
    useEffect(() => {
        tobitEmojione.ascii = true;
        tobitEmojione.imageTitleTag = false;
        tobitEmojione.imagePathPNG = 'https://api.chayns-static.space/emojione/4.0/png/64/';
        tobitEmojione.blacklistChars = '*,#,®,™,©,0,1,2,3,4,5,6,7,8,9';
    }, []);

    const threads = useMemo(() => {
        if (!intercom.shownThreads || intercom.shownThreads.length === 0) {
            return [];
        }
        return intercom.shownThreads;
    }, [intercom?.totalThreads, intercom?.shownThreads]);

    const list = useMemo(() => {
        if (showLoadingAnimation) {
            return [];
        }
        return threads || [];
    }, [showLoadingAnimation, threads]);

    const isItemLoaded = useCallback((index) => !!list[index], [list]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleLoad = useCallback(throttle(async ({ stopIndex }) => {
        const skip = intercom.isSearching ? intercom.searchThreads.length : intercom.threads.length;
        const take = stopIndex - skip;
        if (skip === 0 || skip === lastSkip || take <= 0) {
            return;
        }
        setLastSkip(skip);
        dispatch(handleLoadIntercom({
            token: user?.token,
            skip,
            take,
            searchString: messageSearchString,
        }));
    }, 200, { leading: false }), [user, list, lastSkip, intercom.searchThreads, intercom.threads, intercom.isSearching, messageSearchString]);

    useEffect(() => {
        setLastSkip(0);
    }, [intercom.isSearching]);

    const itemCount = list?.length;

    const backgroundColor = (() => {
        if (!isMyChaynsApp) {
            if (width >= MOBILE_VIEW_BREAKPOINT) {
                return colorMode === 'light' ? 'white' : '#1E1E1E';
            }
            return colorMode === 'light' ? '#f7f7f7' : '#121212';
        }
        return 'unset';
    })();

    const listElement = useCallback(({ index, style }) => {
        const item = list[index];
        if (!item) {
            return null;
        }
        return (
            <div
                style={style}
                key={item}
            >
                <IntercomItem
                    id={item}
                />
            </div>
        );
    }, [list]);

    const handleSetListRef = useCallback((ref) => {
        messagesRef.current = ref;
    }, []);

    return (
        <>
            <div
                ref={handleSetListRef}
                className={classNames('messages-wrapper', {
                    'mobile-view': width < MOBILE_VIEW_BREAKPOINT,
                })}
                style={{
                    height: '100%',
                    backgroundColor,
                    marginTop: (width < MOBILE_VIEW_BREAKPOINT && !infocenter) ? 8 : 0,
                }}
            >
                {(width >= MOBILE_VIEW_BREAKPOINT || infocenter) && intercom.fetchedData && (
                    <MessageSearch infocenter={infocenter}/>
                )}

                {list.length > 0 && (
                    <div className="messages-wrapper__loader">
                        <InfiniteLoader
                            isRowLoaded={isItemLoaded}
                            loadMoreRows={handleLoad}
                            rowCount={10000000000}
                        >
                            {({ onRowsRendered, registerChild }) => {
                                if (width < MOBILE_VIEW_BREAKPOINT && !infocenter) {
                                    return (
                                        <WindowScroller>
                                            {({ height: windowScrollerHeight, isScrolling, scrollTop, onChildScroll }) => ((
                                                <List
                                                    ref={registerChild}
                                                    autoHeight
                                                    height={windowScrollerHeight || 500}
                                                    width={width}
                                                    onRowsRendered={onRowsRendered}
                                                    isScrolling={isScrolling}
                                                    rowCount={itemCount}
                                                    onScroll={onChildScroll}
                                                    rowHeight={64}
                                                    rowRenderer={listElement}
                                                    scrollTop={scrollTop}
                                                    overscanRowCount={20}
                                                    style={{
                                                        backgroundColor,
                                                        paddingBottom: !isMyChaynsApp ? '70px' : 0,
                                                    }}
                                                    className="cc__list"
                                                />
                                            )
                                            )}
                                        </WindowScroller>
                                    );
                                }
                                return (
                                    <AutoSizer>
                                        {({ height, width: autoSizerWidth }) => {
                                            let calcWidth = autoSizerWidth;
                                            if (!calcWidth) {
                                                if (width * 0.2 <= 250) {
                                                    calcWidth = 250;
                                                } else if (width * 0.2 >= 420) {
                                                    calcWidth = 420;
                                                } else {
                                                    calcWidth = Math.round(width * 0.2);
                                                }
                                            }
                                            return (
                                                <List
                                                    height={height}
                                                    ref={registerChild}
                                                    width={calcWidth - 1}
                                                    onRowsRendered={onRowsRendered}
                                                    rowCount={itemCount}
                                                    rowHeight={64}
                                                    rowRenderer={listElement}
                                                    overscanRowCount={10}
                                                    style={{
                                                        backgroundColor,
                                                        overflowX: 'auto',
                                                    }}
                                                    className="scrollbar cc__list"
                                                />
                                            );
                                        }}
                                    </AutoSizer>
                                );
                            }}
                        </InfiniteLoader>
                    </div>
                )}

                {showLoadingAnimation && (
                    <div
                        className="message-wait-cursor"
                    >
                        <SmallWaitCursor show/>
                    </div>
                )}

                {list.length === 0 && !showLoadingAnimation && (
                    <p
                        className="chayns__color--text"
                        style={{
                            textAlign: 'center',
                            marginTop: '20px',
                        }}
                    >
                        {
                            intercom.hasError
                                ? textStrings?.['txt_chayns_de_message_error'] || 'Deine Nachrichten konnten nicht geladen werden.'
                                : textStrings?.['txt_chayns_de_no_messages'] || 'Keine Nachrichten'
                        }
                    </p>
                )}
            </div>
            <style jsx global>
                {`
                    .messages-wrapper {
                        width: ${infocenter ? '100%;' : '20%;'};
                        min-width: 250px;
                        max-width: ${infocenter ? '' : '420px;'};
                        z-index: 1;
                        height: ${infocenter ? '' : 'auto !important;'};
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        
                        &__loader {
                            height: 100%;
                            flex-grow: 1;
                        } 
                        
                        &:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            border-right: 1px solid ${colorMode === 'dark' ? 'rgba(255, 255, 255, .15)' : 'rgba(0, 0, 0, 0.15)'};
                        }

                        &.mobile-view {
                            width: 100%;
                            margin-left: 0;
                            border-right: none;
                            max-width: unset;
                        }

                        .info-center-headline {
                            margin: 10px 0 9px 9px;
                        }

                        .list-mobile-view {
                            overflow: hidden !important;
                            margin-top: 5px;
                        }

                        .message-wait-cursor {
                            justify-content: center;
                            display: flex;
                            margin-top: 20px;
                            height: ${infocenter ? '100vh' : ''};
                            align-items: ${infocenter ? 'center' : ''};
                        }
                    }
                `}
            </style>
        </>
    );
};

Messages.propTypes = {
    infocenter: PropTypes.bool,
};

Messages.defaultProps = {
    infocenter: false,
};

Messages.displayName = 'Messages';

export default memo(Messages);

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import TAPPIDS from '../../constants/tapp-ids';

import styles from './left-bar.module.scss';
import LeftBarIcon from './left-bar-icon/LeftBarIcon';
import { setIFramePointerEvents } from '../../redux-modules/app/actions';

let moveResize = 0;
const LeftBar = ({
    handleClick,
    darkMode,
    activeTapp,
    icons,
    activeSmartClientTapp,
    handleSmartClientButtonClick,
    expandedMenu,
}) => {
    const [emailOpen, setEmailOpen] = useState(activeTapp === TAPPIDS.SMART_CLIENT);
    const [emailSelected, setEmailSelected] = useState(emailOpen);
    const [expanded, setExpanded] = useState(expandedMenu || false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [startWidth, setStartWidth] = useState(expanded ? expandedMenu : null);
    const [resizeActive, setResizeActive] = useState(false);
    const [startResize, setStartResize] = useState(0);
    const [widthState, setWidth] = useState(startWidth);
    const [showText, setShowText] = useState(widthState > 150);
    const [showTooltips, setShowTooltips] = useState(widthState <= 62);
    const dispatch = useDispatch();

    const timeoutRef = useRef(0);

    const lastResizeRef = useRef(0);

    useEffect(() => {
        setShowText(widthState > 80);

        clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout(() => {
            document.cookie = `expandedMenu=${widthState || 62};path=/;max-age=${30 * 86400}`; // 30 days
        }, 500);

        setShowTooltips(widthState <= 62);

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [widthState]);

    useEffect(() => {
        setEmailOpen(activeTapp === TAPPIDS.SMART_CLIENT);
    }, [activeTapp]);

    const handleResize = () => {
        if ((Date.now() - lastResizeRef.current) < 100) return;

        if (window.innerWidth < 1300) {
            setWidth(62);
        } else {
            setWidth(150);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return (() => {
            window.removeEventListener('resize', handleResize);
        });
    }, []);

    const mouseMove = (e) => {
        moveResizeFunction(e, false);
    };

    useEffect(() => {
        dispatch(setIFramePointerEvents(resizeActive));
        if (resizeActive) {
            window.addEventListener('mousemove', mouseMove);
            window.addEventListener('mouseup', endResizeFunction);
        } else {
            window.removeEventListener('mousemove', mouseMove);
            window.removeEventListener('mouseup', endResizeFunction);
        }
        return () => {
            window.removeEventListener('mousemove', mouseMove);
            window.removeEventListener('mouseup', endResizeFunction);
        };
    }, [resizeActive]);

    const handleIconClick = (tappId) => {
        if (tappId === TAPPIDS.SMART_CLIENT) {
            setEmailSelected(!emailSelected);
        } else {
            setEmailSelected(false);
        }
        handleClick(tappId);
    };

    const resizeRef = useRef(null);
    const activeBarTapp = activeTapp === TAPPIDS.SMART_CLIENT ? activeSmartClientTapp : activeTapp;

    const startResizeFunction = (e, isTouch) => {
        setResizeActive(true);
        setStartResize(isTouch ? (e.touches[0].clientX) : (e.clientX));
        if (resizeRef?.current?.clientWidth) {
            setStartWidth(resizeRef.current.clientWidth);
        }
    };

    const moveResizeFunction = (e, isTouch) => {
        if (resizeActive) {
            e.preventDefault();
            const move = isTouch ? (e.touches[0].clientX) : (e.clientX);
            moveResize = move - startResize;
            setInitialLoad(false);
            const newWidth = Math.max(62, Math.min(startWidth + moveResize, 300));

            if (newWidth === 300 || newWidth === 62) {
                setResizeActive(false);
            }
            setWidth(newWidth);
        }
    };

    const endResizeFunction = () => {
        setResizeActive(false);

        setWidth((oldWith) => (
            oldWith > 150 ? oldWith : oldWith > 106 ? 150 : 62
        ));
    };

    return (
        <div
            className={classNames(styles.leftBar, { [styles.dragging]: resizeActive })}
            style={{ width: widthState }}
            ref={resizeRef}
        >
            <div className={styles.alignmentWrapper}>
                <div className={styles.bottomAlign}>
                    {
                        icons.map((x) => (
                            <LeftBarIcon
                                key={`${x.icon}${x.tappId}`}
                                icon={x}
                                handleClick={(tappId) => {
                                    if (x.smartClientTapp) {
                                        handleSmartClientButtonClick(tappId);
                                    } else {
                                        handleIconClick(tappId);
                                    }
                                }}
                                barActive={activeBarTapp === x.tappId || (TAPPIDS.SMART_CLIENT === x.tappId && !emailSelected && activeTapp === TAPPIDS.SMART_CLIENT)}
                                darkMode={darkMode}
                                expanded={showText}
                                initialLoad={initialLoad}
                                showTooltips={showTooltips}
                                dragging={resizeActive}
                            />
                        ))
                    }
                </div>
            </div>
            <div
                className={styles.handle}
                onMouseDown={(e) => startResizeFunction(e, false)}
                onMouseMove={(e) => moveResizeFunction(e, false)}
                onTouchStart={(e) => startResizeFunction(e, true)}
                onTouchMove={(e) => moveResizeFunction(e, true)}
                onTouchEnd={endResizeFunction}
                onTouchCancel={endResizeFunction}
            />
        </div>
    );
};

LeftBar.propTypes = {
    handleClick: PropTypes.func.isRequired,
    darkMode: PropTypes.bool,
    activeTapp: PropTypes.number.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    icons: PropTypes.array,
    activeSmartClientTapp: PropTypes.string,
    handleSmartClientButtonClick: PropTypes.func.isRequired,
};

LeftBar.defaultProps = {
    darkMode: null,
    icons: null,
    activeSmartClientTapp: null,
};

export default LeftBar;

import React, { FC } from 'react';
import { SmallWaitCursor } from '@chayns-components/core';

const LoadingComponent: FC = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
        <SmallWaitCursor/>
    </div>
);

export default LoadingComponent;

const parseVersion = (version: string): [number, number, number] => {
    const v = version
        .split('.')
        .map(((p) => Number.parseInt(p, 10)))
        .map((p) => (Number.isNaN(p) ? 0 : p));
    while (v.length < 3) {
        v.push(0);
    }
    return v.slice(0, 3) as [number, number, number];
};

/**
 * compares two version numbers with each other. versions should have a format like 0.0.0
 * @param version1
 * @param version2
 * @return -1 if version1 is smaller, 1 if version1 is greater and 0 if both versions are the same
 */
export const compareVersion = (version1: string, version2: string) => {
    const v1 = parseVersion(version1);
    const v2 = parseVersion(version2);

    for (let i = 0; i < v1.length; i++) {
        if (v1[i] < v2[i]) return -1;
        if (v1[i] > v2[i]) return 1;
    }
    return 0;
};

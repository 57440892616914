import cloneDeep from 'lodash.clonedeep';
import { useEffect, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleLoadIntercom, handleLoadThreadById, setIntercomThreads, setUpdatedIntercomThread } from '../../redux-modules/intercom/actions';
import addOnActivateListener from '../addOnActivateListener';
import usePrevious from './usePrevious';
import { loadIntercomWebsocket } from '../../redux-modules/app/actions';
import { intercomWsClientObj } from '../../components/util-components/IntercomWebsocketComponent';
import { getIntercomState } from '../../redux-modules/intercom/selector';
import { getAppState } from '../../redux-modules/app/selector';
import { ChatWebSocketClient, WebSocketMessage } from '../../types/intercom/websocket';
import { getUserState } from '../../redux-modules/user/selector';
import { isPRODUCTION, isSTAGING } from '../../constants/environments';

const useIntercom = () : void => {
    const dispatch = useDispatch();
    const [webSocketClient, setWebSocketClient] = useState<ChatWebSocketClient>(null);
    const {
        isChaynsReady,
        selectedItem,
        isUserAuthenticated,
    } = useSelector(getAppState);
    const { token } = useSelector(getUserState);
    const intercom = useSelector(getIntercomState);
    const previousSelectedItem = usePrevious(selectedItem);

    const intercomLoading = useRef(false);

    const handleUpdateThreads = useCallback(() => {
        dispatch(handleLoadIntercom({
            token,
        }));
    }, [dispatch, token]);

    const handleAddMessage = useCallback(({ message }) => {
        const findThread = intercom?.totalThreads?.[message.threadId];
        if (findThread) {
            const updatedThread = cloneDeep(findThread);
            updatedThread.lastMessage = message;
            updatedThread.lastMessageTime = message.creationTime;
            dispatch(setUpdatedIntercomThread({
                thread: updatedThread,
            }));
        } else {
            setTimeout(() => {
                dispatch(handleLoadThreadById({
                    id: message.threadId,
                    token,
                }));
            }, 10 * 1000);
        }
    }, [dispatch, intercom?.totalThreads, token]);

    const handleThreadUpdate = useCallback((thread) => {
        setTimeout(() => {
            dispatch(handleLoadThreadById({
                id: thread.id || thread.threadId,
                token,
            }));
        }, 1000);
    }, [dispatch, token]);

    const handleReadStatusUpdate = useCallback((message: WebSocketMessage) => {
        const thread = intercom.totalThreads?.[message.threadId];

        if (thread) {
            const updatedThread = {
                ...thread,
                members: thread.members.map((member) => {
                    if (member.id === message.member.id) {
                        return {
                            ...member,
                            unreadFlag: false,
                            unreadCount: 0,
                        };
                    }
                    return member;
                }),
            };
            dispatch(setUpdatedIntercomThread({
                thread: updatedThread,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, intercom.totalThreads]);

    useEffect(() => {
        let loadedIntercomWebsocketCallback: EventListener;
        if (isChaynsReady && !webSocketClient && isUserAuthenticated) {
            dispatch(loadIntercomWebsocket(true));
            const timeout = setTimeout(handleUpdateThreads, 5 * 1000);
            loadedIntercomWebsocketCallback = () => {
                clearTimeout(timeout);
                if (intercomLoading.current) {
                    return;
                }
                intercomLoading.current = true;
                const { client } = intercomWsClientObj;
                setWebSocketClient(client);
                client.initialize({
                    environment: isPRODUCTION || isSTAGING ? 'production' : 'qa',
                });

                client.onAuthStateChange = (message: string) => {
                    if (message === 'successful') {
                        handleUpdateThreads();
                    }
                };
            };
            window.addEventListener('loaded-intercom-websocket', loadedIntercomWebsocketCallback);
        }

        return () => {
            if (loadedIntercomWebsocketCallback) {
                window.removeEventListener('loaded-intercom-websocket', loadedIntercomWebsocketCallback);
            }
        };
    }, [dispatch, handleAddMessage, handleReadStatusUpdate, handleThreadUpdate, handleUpdateThreads, isChaynsReady, isUserAuthenticated, webSocketClient]);

    useEffect(() => {
        if (webSocketClient) {
            webSocketClient.on('addMessage', handleAddMessage);
        }

        return () => {
            if (webSocketClient) {
                webSocketClient.off('addMessage', handleAddMessage);
            }
        };
    }, [handleAddMessage, webSocketClient]);

    useEffect(() => {
        if (webSocketClient) {
            webSocketClient.on('readStatus', handleReadStatusUpdate);
        }

        return () => {
            if (webSocketClient) {
                webSocketClient.off('readStatus', handleReadStatusUpdate);
            }
        };
    }, [handleReadStatusUpdate, webSocketClient]);

    useEffect(() => {
        if (webSocketClient) {
            webSocketClient.on('unreadStatus', handleThreadUpdate);
            webSocketClient.on('threadUpdate', handleThreadUpdate);
        }

        return () => {
            if (webSocketClient) {
                webSocketClient.off('unreadStatus', handleThreadUpdate);
                webSocketClient.off('threadUpdate', handleThreadUpdate);
            }
        };
    }, [handleThreadUpdate, webSocketClient]);

    useEffect(() => {
        if (!isUserAuthenticated && webSocketClient) {
            webSocketClient.disconnect({ shouldReconnect: false });
            setWebSocketClient(null);
        }
    }, [isUserAuthenticated, webSocketClient]);

    useEffect(() => {
        if (previousSelectedItem) {
            dispatch(setIntercomThreads({
                result: [],
                selectedItem,
                updateLastFetch: false,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, selectedItem]);

    useEffect(() => {
        const cb = (isVisible: boolean) => {
            if (webSocketClient) {
                if (isVisible) {
                    webSocketClient.connect({ shouldReconnect: true });
                } else {
                    webSocketClient.disconnect({ shouldReconnect: false });
                }
            }
        };
        return addOnActivateListener(cb);
    }, [isChaynsReady, webSocketClient]);
};

export default useIntercom;

import * as jsonCallFunctions from './calls';
import { redirectToSite } from "./calls/redirectToSite";

const jsonCalls = {
    1: jsonCallFunctions.toggleWaitCursor,
    2: jsonCallFunctions.selectTapp,
    4: jsonCallFunctions.showPictures,
    14: jsonCallFunctions.requestGeoLocation,
    15: jsonCallFunctions.showVideo,
    18: jsonCallFunctions.getGlobalData,
    34: jsonCallFunctions.scanQrCode,
    52: jsonCallFunctions.setTobitAccessToken,
    54: jsonCallFunctions.tobitLogin,
    55: jsonCallFunctions.renewToken,
    56: jsonCallFunctions.tobitLogout,
    66: jsonCallFunctions.setAccessTokenChangeListener,
    72: jsonCallFunctions.showFloatingButton,
    73: jsonCallFunctions.setObjectForKey,
    74: jsonCallFunctions.getObjectForKey,
    75: jsonCallFunctions.addChaynsCallErrorListener,
    77: jsonCallFunctions.setIframeHeight,
    84: jsonCallFunctions.updateNavigation,
    78: jsonCallFunctions.getWindowMetric,
    81: jsonCallFunctions.scrollToPosition,
    92: jsonCallFunctions.updateChaynsId,
    102: jsonCallFunctions.addScrollListener,
    112: jsonCallFunctions.sendEventToTopFrame,
    114: jsonCallFunctions.setWebsiteTitle,
    115: jsonCallFunctions.setTobitAccessToken,
    127: jsonCallFunctions.getSavedIntercomChats,
    128: jsonCallFunctions.setIntercomChatData,
    129: jsonCallFunctions.closeWindow,
    137: redirectToSite,
    248: jsonCallFunctions.hideDialog,
    185: jsonCallFunctions.closeDialog,
    205: () => {}, // setScroll
    217: () => {}, // handled via dialog.js
    218: () => {}, // handled via dialog.js
    219: () => {}, // handled via dialog.js
    221: jsonCallFunctions.setTappCookie,
    222: jsonCallFunctions.getTappCookie,
    254: jsonCallFunctions.addDesignSettingsChangeListener,
    255: jsonCallFunctions.openScanner,
};

if (!navigator.userAgent?.match(/(chayns\/)(\d+)/i)) {
    window.JsonCalls = jsonCalls;
}

export default jsonCalls;

import { getTobitAccessToken } from '../calls/json-native-calls/calls';
import { decodeTobitAccessToken } from './convert';
import TAPPIDS from '../constants/tapp-ids';
import { Tapp } from '../types/chayns';
import logger from "../constants/logger";
import { prefix } from "../constants/defaults";

export async function updateUserData(isGuardedAccount: boolean): Promise<boolean> {
    try {
        const accessToken = await getTobitAccessToken(isGuardedAccount);
        const payload = decodeTobitAccessToken(accessToken);

        window.chaynsInfo.User = {
            ID: payload && payload.TobitUserID ? payload.TobitUserID : 0,
            FirstName: payload && payload.FirstName ? payload.FirstName : '',
            LastName: payload && payload.LastName ? payload.LastName : '',
            PersonID: payload && payload.PersonID ? payload.PersonID : '',
            TobitAccessToken: accessToken,
            UACGroups: [],
        };

        window.globalData.AppUser = {
            UACGroups: [],
            FacebookAccessToken: '',
            FacebookUserName: payload && payload.FirstName && payload.LastName ? `${payload.FirstName} ${payload.LastName}` : '',
            FacebookID: payload && payload.FacebookUserID ? payload.FacebookUserID : '',
            FirstName: payload && payload.FirstName,
            LastName: payload && payload.LastName,
            PersonID: payload && payload.PersonID ? payload.PersonID : '',
            TobitUserID: payload && payload.TobitUserID ? payload.TobitUserID : 0,
            TobitAccessToken: accessToken,
            AdminMode: false,
        };

        if (typeof chayns !== 'undefined') {
            chayns.getGlobalData();
        }

        return true;
    } catch (e) {
        logger().error({
            ex: {
                message: e.message,
                stackTrace: e.stack,
            },
        });

        return false;
    }
}

export function mapToApiTapp(siteId: string, tapp: { id: number, path: string, }): { Id: number, customUrl: string } {
    return {
        Id: tapp.id,
        customUrl: tapp.path ? tapp.path.replace(`/${siteId}`, '').slice(tapp.path.charAt(0) === '/' ? 1 : 0) : '',
    };
}

export async function loadTapps(): Promise<boolean> {
    try {
        const token = (() => {
            if (typeof chaynsInfo !== 'undefined') return window.chaynsInfo.User?.TobitAccessToken;
            if (typeof chayns !== 'undefined') return chayns.env.user?.tobitAccessToken;
            return null;
        })();

        const response = await fetch('https://service-rpc.chayns.net/60021-08989/NavigationItems?Fields=data(id,type,source,bundleurl,shownames,icon,children,ishidden,queryparameters),davidWebAccesses(apiVersion,davidSiteId,endpoint,serverName)', {
            method: 'GET',
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
            },
        });

        if (response.status === 200) {
            const result = await response.json() as {
                data: Array<{
                    id: number,
                    source?: string,
                    customUrl?: string,
                }>,
                davidWebAccesses: Array<{ apiVersion: string; davidSiteId: string; endpoint: string; }>
            };

            const tapps = result.data;
            const walletIndex = tapps.findIndex(x => x.id === 250357);
            if (walletIndex !== -1) {
                tapps[walletIndex].apiVersion = 5;
            }
            const deviceSetupIndex = tapps.findIndex(x => x.id === 412464);
            if (deviceSetupIndex !== -1) {
                tapps[deviceSetupIndex].apiVersion = 5;
            }
            const intercomIndex = tapps.findIndex(x => x.id === 251441);
            if (intercomIndex !== -1) {
                tapps[intercomIndex].apiVersion = 5;
                tapps[intercomIndex].source = `https://tapp${prefix}.chayns-static.space/intercom/v3.1/full-view/index.html?OS=chaynsnet-runtime&AppVersion=2`;
            }
            const sidekickIndex = tapps.findIndex(x => x.id === 734886);
            if (sidekickIndex !== -1) {
                tapps[sidekickIndex].apiVersion = 5;
                tapps[sidekickIndex].source += '?OS=chaynsnet-runtime&AppVersion=2';
            }
            const callingCodeSetupIndex = tapps.findIndex(x => x.id === 495401);
            if (callingCodeSetupIndex) {
                tapps[callingCodeSetupIndex].apiVersion = 5;
            }

            const smartClientIndex = tapps.findIndex((tapp) => tapp.id === 662163);

            if (smartClientIndex > -1) {
                tapps[smartClientIndex].id = TAPPIDS.SMART_CLIENT;
                tapps.push({
                    id: TAPPIDS.CALENDAR,
                    customUrl: 'Kalender',
                });
            }

            window.chaynsInfo.Tapps = tapps;
            window.chaynsInfo.davidWebAccesses = result.davidWebAccesses;
            window.ChaynsInfo.davidWebAccesses = result.davidWebAccesses;
        }

        window.chaynsInfo.Tapps.push({
            id: TAPPIDS.LOGIN_TAPP_ID,
            url: window.chaynsInfo.loginTappUrl,
            isHidden: true
        });

        window.chaynsInfo.Tapps.push({
            id: TAPPIDS.LANDING_PAGE,
            url: window.chaynsInfo.startTappUrl,
        });

        window.globalData.AppInfo.Tapps = window.chaynsInfo.Tapps.map((t) => ({
            customUrl: t.customUrl,
            Id: t.id,
            InternalName: t.internalName,
            isExclusiveView: t.isExclusiveView,
            HideFromMenu: t.hideFromMenu,
            minAge: t.minAge,
            ShowName: t.showName,
            UserGroupIds: t.uacGroupIds,
        }));

        return true;
    } catch (e) {
        window.chaynsInfo.Tapps = [{
            id: TAPPIDS.LOGIN_TAPP_ID,
            url: window.chaynsInfo.loginTappUrl,
        }];
        window.globalData.AppInfo.Tapps = window.chaynsInfo.Tapps;

        return false;
    }
}

export function setSelectedTapp(tapp: Tapp): void {
    if (tapp && typeof tapp === 'object') {
        window.chaynsInfo.ExclusiveMode = tapp.exclusiveView || false;
        window.globalData.AppInfo.TappSelected = {
            Id: tapp.id,
            Url: tapp.url,
            customUrl: tapp.customUrl,
        };
    }
}
